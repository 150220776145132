import React, {useState} from 'react';
import Layout from '../../hoc/Layout/Layout';
import { Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    btnOval : {
	     ...theme.shape.oval
	  }
	}));


const ForgetPassword = (props) => {

	const [email,setEmail] = useState('');

	const [submitted,setSubmitted] = useState(false);
	
	const classes = useStyles();

	let errMsg = null;

	if(props.error) {
		errMsg = (
				<p>{props.error}</p>
			);
	}

	let successMsg = null;

	if(props.success) {
		successMsg = (
				<p>{props.success}</p>
			);
	}	

	const inputStyle = {
 	width : '60%',
 	marginBottom: '20px'
 	}


	const handleChange = (event) => {
			setEmail(event.target.value);
		}

	const handleSubmit = (event) => {
			props.onForgetPassword(email);
			setEmail('');
		}

	const welcomeBanner = {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center',
	};


	const bannerGrid = {
		paddingTop: '10px',
		alignItems: 'flex-start'
  	};


	return (
			<Layout>
				<Grid style={bannerGrid} item xs={12}>
					<Typography style={welcomeBanner} variant="h5">
					Forgot Password
					</Typography>
				</Grid>
				<Grid container style={{paddingTop:'40px'}}>
					<Grid item xs={12}>
					<ValidatorForm onSubmit={handleSubmit} >
					<Typography variant="body1" color="error">{errMsg}</Typography>
					<Typography variant="body1" color="primary">{successMsg}</Typography>
					<Grid item xs={12}>
					 <TextValidator
					 style={inputStyle}
					 variant="outlined"
			         label="Email"
			         onChange={ handleChange }
			         name="email"
			          value = {email}
			         validators = {['required','isEmail']} 
			         errorMessages={['this field is required','email is not valid']}
			        />
			        <input type="hidden" name="link" value={process.env.REACT_APP_URL + '/' + 'reset-password'} />
			       </Grid>
					<Grid item xs={12}>
		       		 <Button className = {classes.btnOval} type="submit" disabled = {submitted}  variant="contained" color="secondary" disableElevation>
		       		 Reset Link
			        </Button>
			        </Grid>
			         </ValidatorForm>
			         </Grid>
		        </Grid>
	        </Layout>
		);
}


const mapStateToProps = state => {
	return {
		error: state.user_reducer.error,
		success: state.user_reducer.success,
		userId : state.auth_reducer.userId
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		onForgetPassword : (email) => dispatch(actions.onForgetPassword(email))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ForgetPassword);