import * as actionTypes from './actionTypes';
import axios from 'axios';


export const setTrails = (trails) => {
	return {
		type : actionTypes.SET_TRAILS,
		trails : trails
	}
}

export const setAwardStatus = (status) => {
	return {
		type : actionTypes.SET_AWARD_STATUS,
		award_status : status
	}
}

export const getTrails = () => {
	return dispatch => {
		axios.get(process.env.REACT_APP_API_URL + '/trails')
			.then(response=> {
				if(response.data.success==true)
				{
				dispatch(setTrails(response.data.trails))	 
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const editTrail = (formData, callback) => {
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/trail/'+formData.idTrail, {
			TrailName: formData.TrailName,
			idTrail: formData.idTrail })
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(getTrails());
					if (callback && typeof callback === 'function') {
						callback();
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const deleteTrail = (formData, callback) => {
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/trail/'+formData.idTrail, {
			TrailName: formData.TrailName, 
			idTrail: formData.idTrail,
			Delete: 1 })
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(getTrails());
					if (callback && typeof callback === 'function') {
						callback();
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const submitNewTrail = (formData, callback) => {
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/trail', {
    		TrailName: formData.TrailName })
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(getTrails());
					if (callback && typeof callback === 'function') {
						callback();
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const giveUserAward = (id_object,award_type,user_id) => {
	return dispatch => {
		
		let userId = user_id;
		
		if(userId==null)
		{
			userId = localStorage.getItem('userId');
		}

		if(!userId){
			dispatch(setAwardStatus('You must login first'));
		}

		axios.post(process.env.REACT_APP_API_URL + '/award', {
    		Value: 1, idObject: id_object , idAwardType : award_type , idUser : userId })
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(setAwardStatus(response.data.message));
				} else if (response.data.success==false){
					dispatch(setAwardStatus(response.data.message));
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}