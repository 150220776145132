import React, {useEffect} from 'react';
import logo from '../../../assets/logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Container, Hidden, Grid } from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import SideDrawer from "../SideDrawer";
import Typography from '@material-ui/core/Typography';
import * as actions from '../../../store/actions/index';
import  {connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  	logo : {
		width: '175px'
	},
	img : {
		maxHeight: '100px'
	},
	navbarDisplayFlex: {
		display: `flex`,
		justifyContent: `end`
	},
	navbarContainer: {
		backgroundColor: 'white'
	},
	mainNav: {
		width: '88%'
	},
	navLinkGrid: {
		position: 'relative'
	},
	linkText: {
		textDecoration: `none`,
		textTransform: `uppercase`,
		color: `black`,
		fontWeight: '700',
		position: 'absolute',
		bottom: '0',
		'&:hover': {
			color: '#28DBE8'
		},
		cursor: 'pointer'
	}
}));

const MainNavigation = (props) => {

	const classes = useStyles();
	const logo_style = {};
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	if (isMobile) {
		logo_style.paddingLeft = '40px';
	}

	useEffect(() => {
		if(props.userId){
	  		props.loadActivity(props.userId);
	  	}
	  },[]);

	const navLinks = [
		{ title: `Dashboard`, path: `/dashboard` },
		{ title: `Touch Points`, path: `/do-more` },
		{ title: `Contact`, path: `/contact` }
	]

	let iconLink = "/";

	let admins = [8,9,13,55,61];
	let navSize = 3;
	let logoutNavSize = 3;

	if (props.userId) {
		iconLink = "/friend";
		// if (admins.includes(parseInt(props.userId))) {
		const user = JSON.parse(localStorage.getItem('user'));
		if ( user && user.isAdmin ) {
			navLinks.push({title: 'Admin', path: '/admin'});
			navSize = 3;
			logoutNavSize = 2;
		}
	}

	function getUserInfo() {
		if (!props.userId || !props.lake || props.lake==false) {
			return (
				<span>
				</span>
			)
		}

		return (
			<Container>
				<Grid container alignItems="center" item xs={12}>
					<img className = {classes.img} src={process.env.REACT_APP_URL + '/images/lakes/' + props.lake.Img} width="75" />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1">
						{props.lake.LakeName}
					</Typography>
				</Grid>
			</Container>
		)
	}

	function getLoginLogoutLink() {
		if (props.userId) {
			return (
				<a className={classes.linkText} onClick = { ()=>props.onLogOut()}>
					<Typography variant="h6">Logout</Typography>
				</a>
			)
		} else {
			return (

				<NavLink key={"login"} to="/auth" className={classes.linkText}>
					<Typography variant="h6">Login</Typography>
				</NavLink>
			)
		}
	}

	return (
		<AppBar className={classes.navbarContainer} position="static">
      		<Toolbar style={{paddingLeft:'0px'}} >
			  	<Container className={classes.navbarDisplayFlex}>

			  		<Grid style={logo_style} container item xs={10} sm={10} md={4}>

						<Grid container justify="center" item xs={12} sm={12} md={6}>
							<Link style={{ textDecoration: 'none' }} to={iconLink}>
								<IconButton edge="start" color="inherit" aria-label="home">
									<img className={classes.logo} src={logo} alt="friends_of_the_fingerlakes_logo"/>
								</IconButton>
							</Link>
						</Grid>
						<Grid container item xs={12} sm={12} md={6}>
							<Grid item xs={12}>
								{props.userId && props.user ? "Hello "+props.user.FirstName : ""}
							</Grid>

							<Grid item xs={12}>
								 {getUserInfo()}
							</Grid>
						</Grid>

					</Grid>

					<Grid container justify="space-between" item xs={2} sm={2} md={8}>
						<Hidden smDown>
							{navLinks.map(({ title, path }, index) => (
								<Grid container justify="center" key={String(index)}
									  item xs={title === "Admin" || title === "Contact" ? 2 : navSize}>
									<NavLink key={"nav_"+title.replace(' ', '')} to={path} className={classes.linkText} activeClassName="selected">
										<Typography variant="h6">{title}</Typography>
									</NavLink>
								</Grid>
							))}
							<Grid container justify="center" item xs={logoutNavSize}>
								{getLoginLogoutLink()}
							</Grid>
						</Hidden>
						<Hidden mdUp>
							<SideDrawer props={props} navLinks={navLinks}/>
						</Hidden>
					</Grid>
				</Container>
			</Toolbar>
    	</AppBar>

	);
}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		lakeId : state.user_reducer.lakeId,
		lake : state.user_reducer.lake,
		token : state.auth_reducer.token,
		user: state.user_reducer.user
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId)),
		lakeLoad : (lakeId) => dispatch(actions.getLake(lakeId)),
		onLogOut : () => dispatch(actions.authLogout()),
		loadActivity : (userId) => dispatch(actions.loadActivity(userId))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(MainNavigation);
