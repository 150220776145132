import React from 'react';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../../components/UI/Button/CustomButton';
import Layout from '../../hoc/Layout/Layout';
import  {connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
  	},
	progressBar: {
		minHeight: '50px',
		width: '50%'
	},
	trailMessage: {
		paddingTop: '50px'
	}
}));

const Scan = (props) => {
	const classes = useStyles();
	const btnBlue = '#28DBE8';

	function getTrailMessage() {
		if (props.activity && props.activity["5"] && props.trails) {
			let percentComplete = ((props.activity["5"] / props.trails.length)*100).toFixed(2)+"%";
			return (
				<span>
					You have completed {props.activity["5"]} out of {props.trails.length} trails ({percentComplete}).
				</span>
			);
		} else if (props.trails) {
			return (
				<span>
					You have completed 0 out of {props.trails.length} trails (0%).
				</span>
			);
		}
	}

	function getPercentComplete() {
		let percentComplete = 0;
		if (props.activity && props.activity["5"] && props.trails) {
			percentComplete = ((props.activity["5"] / props.trails.length)*100).toFixed(2);
		}

		return percentComplete;
	}

	return (
			<Layout isAuth={props.token}>
				<Grid className={classes.bannerGrid} item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h6">
					Complete a Trail - Use the Scan button below to scan a trail's QR code to record a touchpoint!
					</Typography>
				</Grid>
				
				<CustomButton className="dash-custom-button" redirectLink="/trail-scanner" btnColor={btnBlue}>Scan QR Code</CustomButton>
				<Grid container> 
					<Grid  item xs={12}>
						<Typography >
							The Finger Lakes Museum is pleased to collaborate with the Finger Lakes Land Trust.  See a full trails list on their <a href="https://www.gofingerlakes.org/" target="_blank">Go Finger Lakes website.</a>  At each trail head, you will find a QR code that you can scan to get a trail “touch point”.   Use the app to track how many you have done on your “Trails Dashboard” .
						</Typography>
					</Grid>
				</Grid>

				<Grid container className={classes.trailMessage} justify="center">
					<Grid item xs={12}>
						<Typography variant="h4">
							{getTrailMessage()}
						</Typography>
						<Grid container className={""} justify="center">
							<Grid item xs={12}>
								<progress className={classes.progressBar} value={getPercentComplete()} max={props.trails.length}></progress>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Layout>
		);

}

const mapStateToProps = state => {
	return {
		trails : state.trail_reducer.trails,
		token : state.auth_reducer.token,
		activity : state.user_reducer.activity
 	}
}


export default connect(mapStateToProps)(Scan);