import React from 'react';
import Typography from '@material-ui/core/Typography';
import Layout from '../../hoc/Layout/Layout';
import Link from '@material-ui/core/Link';
import  {connect } from 'react-redux';
import { Redirect} from "react-router-dom";


const Donate = (props) => {

	if(!props.token) {
		return <Redirect to="/auth" />;
	}

	return (
		<Layout footer = {true} showLake={true}>
			<Typography variant="body1" style={{marginBottom : '20px'}}>Click below to Donate Now</Typography>	
			 <Link color="secondary" href="https://flmdonatenow.securepayments.cardpointe.com/pay" target="_blank">
			 <Typography color="secondary" variant="h4">Click Here to Donate</Typography>
			 </Link>
		</Layout>
		);
}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token
 	}
}


export default connect(mapStateToProps)(Donate);