import React, {useEffect} from 'react';
import Layout from '../../hoc/Layout/Layout';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../UI/Button/CustomButton';
import DonateButton from '../UI/Button/DonateButton';
import { Grid } from '@material-ui/core';
import  {connect } from 'react-redux';
import { Redirect} from "react-router-dom";
import * as actions from '../../store/actions/index';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    welcomeBanner: {
      	backgroundColor: '#454545',
      	color: `white`,
		padding: '10px',
		textAlign: 'center',
		marginBotton: '20px'
	},
	bannerGrid: {
		paddingTop: '10px'
	},
	blueButton: {
		color: 'white',
		fontWeight: 'bold'
	},
	greenButton: {
		color: 'white',
		fontWeight: 'bold'
	},
	purpleButton: {
		color: 'white',
		fontWeight: 'bold'
	},
	lightGreenButton: {
		fontWeight: 'bold'
	}
});

const DoMore = (props) => {

	const btnPurple = '#A039A7';
	const btnLightGreen = '#69F1C3';
	const btnDarkBlue = '#3014C4';
	const btnGreen = '#39A765';
	const classes = useStyles();

	useEffect(() => {
		if(props.userId && !props.user){
			props.userLoad(props.userId);
		}
	});

	if(!props.token) {
		return <Redirect to="/auth" />;
	}

	var url_args = "";
	if (props.user) {
		url_args = "?billFName="+props.user.FirstName+"&billLName="+props.user.LastName+"&email="+props.user.Email;
	}

	if (props.lake) {
		url_args += "&cf_Lake_Affilitation="+props.lake.LakeName+"_Lake";
	}

		return (
				<Layout footer={true} showLake={true}>
				<Grid className={classes.bannerGrid} item xs={12}>
			{/*added the word museum aal 2021-5-24*/}
					<Typography className={classes.welcomeBanner} variant="h5">
						Add To Your Finger Lakes Museum Experience
					</Typography>
				</Grid>
				<Grid container>
					{/*
					<Grid item xs={6}>
						<CustomButton redirectLink="#" btnColor={btnLightGreen}>Visit a business</CustomButton> 
					</Grid>
					*/}
					<Grid item xs={12} md={6}>
						<CustomButton redirectLink="/scan" className={classes.blueButton} btnColor={btnDarkBlue}>Complete a trail</CustomButton> 
					</Grid>
					<Grid  item xs={12} md={6}>
						<DonateButton className={classes.greenButton} redirectLink={"https://flmfriendsdonate.securepayments.cardpointe.com/pay"+url_args} btnColor={btnGreen}>Donate</DonateButton> 
					</Grid>
					<Grid  item xs={12} md={6}>
						<CustomButton className={classes.purpleButton} redirectLink="/friend" btnColor={btnPurple}>Add a Friend</CustomButton> 
					</Grid>
					<Grid  item xs={12} md={6}>
						<CustomButton className={classes.lightGreenButton} redirectLink="/dashboard" btnColor={btnLightGreen}>My Dashboard</CustomButton> 
					</Grid>
					<Grid item xs={12} md={6}>
						<CustomButton className={classes.greenButton} redirectLink="/volunteer" btnColor={btnGreen}>Volunteer</CustomButton> 
					</Grid>
			{/*changed event attendance to attend an event*/}
					<Grid item xs={12} md={6}>
						<CustomButton className={classes.purpleButton} redirectLink="/event" btnColor={btnPurple}>Attend an Event</CustomButton> 
					</Grid>
					<Grid item xs={12} />					
				</Grid>
				</Layout>
			);
}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token,
		userId : state.auth_reducer.userId,
		user: state.user_reducer.user,
		lake : state.user_reducer.lake
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId)),
		lakeLoad : (lakeId) => dispatch(actions.getLake(lakeId))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DoMore);