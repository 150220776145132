import * as actionTypes from '../actions/actionTypes';

const initialState = {
	lakes : null,
	error : null,
	loading : true,
	participations : null
}

const reducer = (state = initialState, action) => {

	switch(action.type) {
	  case actionTypes.GET_LAKES:
	    return {
	    	...state,
	    	error : null,
	    	loading : false,
	    	lakes : action.lakes
		}
	  case actionTypes.LOAD_PARTICIPATIONS:
	    return {	
	    	...state,
	    	participations : action.participations
		}
	}
	
	return state;
}


export default reducer;