import React from 'react';
import Layout from '../hoc/Layout/Layout';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';
import  {connect } from 'react-redux';
import * as actions from '../store/actions/index';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    welcomeBanner: {
      	backgroundColor: '#454545',
      	color: `white`,
		padding: '10px',
		textAlign: 'center',
		marginBotton: '20px'
	},
	bannerGrid: {
		paddingTop: '10px'
	},
    orangeButton: {
		backgroundColor: "#DC9A27",
		borderRadius: "20px",
		'&:hover': {
			backgroundColor: "#28DBE8"
		},
        width: '300px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        padding: '8px 22px',
		textDecoration: 'none',
		color: 'black'
	},
	buttonContainer: {
		paddingTop: '30px'
	}
});

const Contact = (props) => {

	const classes = useStyles();

    return (
		<Layout>
			<Grid className={classes.bannerGrid} container>
				<Grid item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h5">
						Contact Us
					</Typography>
				</Grid>
			</Grid>
			<Grid className={classes.bannerGrid} container>
				<Grid className={classes.buttonContainer} item xs={12}>
					<a href="https://www.fingerlakesmuseum.org" target="_blank" className={classes.orangeButton} variant="contained">Visit the Finger Lakes Website</a>
				</Grid>
			</Grid>
			<Grid className={classes.bannerGrid} container>
				<Grid className={classes.buttonContainer} item xs={12}>
					<a href="https://btblock.atlassian.net/servicedesk/customer/portal/1" target="_blank" className={classes.orangeButton} variant="contained">See a problem? Report it to us!</a>
				</Grid>
			</Grid>
		</Layout>
	);
}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token,
		user: state.user_reducer.user,
		lake : state.user_reducer.lake
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId)),
		lakeLoad : (lakeId) => dispatch(actions.getLake(lakeId))
	}
}

export default connect(mapStateToProps)(Contact);