import * as actionTypes from './actionTypes';
import axios from 'axios';


export const setEvents = (events) => {
	return {
		type : actionTypes.SET_EVENTS,
		events : events
	}
}

export const setAwardStatus = (status) => {
	return {
		type : actionTypes.SET_AWARD_STATUS,
		award_status : status
	}
}

export const getEvents = () => {
	return dispatch => {
		axios.get(process.env.REACT_APP_API_URL + '/events')
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(setEvents(response.data.events))	 
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const editEvent = (formData, callback) => {
	//const foo = (...args) => console.log('arguments', args);
	console.log('formData', formData);
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/event/'+formData.idEvent, {
			idEvent: formData.idEvent,
			EventName: formData.EventName,
			IsVolunteer: formData.isVolunteer })
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(getEvents());
					if (callback && typeof callback === 'function') {
						callback();
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const deleteEvent = (formData, callback) => {
	console.log('delete event', formData);
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/event/'+formData.idEvent, {
			idEvent: formData.idEvent,
			EventName: formData.EventName,
			IsVolunteer: formData.isVolunteer, 
			Delete: 1 })
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(getEvents());
					if (callback && typeof callback === 'function') {
						callback();
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const submitNewEvent = (formData, callback) => {

	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/event', {
    		EventName: formData.EventName, IsVolunteer: formData.IsVolunteer })
			.then(response=> {
				console.log(response.data);
				if(response.data.success==true)
				{
					dispatch(getEvents());
					if (callback && typeof callback === 'function') {
						callback();
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const giveUserAward = (id_object,award_type,user_id) => {
	return dispatch => {
		
		let userId = user_id;
		
		if(userId==null)
		{
			userId = localStorage.getItem('userId');
		}

		if(!userId){
			dispatch(setAwardStatus('You must login first'));
		}

		axios.post(process.env.REACT_APP_API_URL + '/award', {
    		Value: 1, idObject: id_object , idAwardType : award_type , idUser : userId })
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(setAwardStatus(response.data.message));
				} else if (response.data.success==false){
					dispatch(setAwardStatus(response.data.message));
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}