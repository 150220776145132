import * as actionTypes from '../actions/actionTypes';

const initialState = {
	user : null,
	lakeId : null,
	error : null,
	success : null,
	loading : true,
	lake : null,
	referId : null,
	activity : null,
	users : null
}

const reducer = (state = initialState, action) => {

	switch(action.type) {
	  case actionTypes.REGISTER_SUCCESS:
	    return {
	    	...state,
	    	error : null,
	    	loading : false,
	    	user : action.user
		}
	  case actionTypes.REGISTER_FAILURE:
	    return {
	    	...state,
	    	error : action.error
		}
	  case actionTypes.SET_USER_LAKE:
	    return {
	    	...state,
	    	lakeId : action.id
		}
	  case actionTypes.GET_LAKE:
	    return {
	    	...state,
	    	error : null,
	    	loading : false,
	    	lake : action.lake
		}
	   case actionTypes.SET_USER_DATA:
	    return {
	    	...state,
	    	user : action.user,
	    	lakeId : action.lakeId
		}
		case actionTypes.SET_USERS:
	    return {
	    	...state,
	    	users : action.users
		}
	  case actionTypes.REMOVE_LAKE:
	    return {
	    	...state,
	    	lakeId : null
		}
	  case actionTypes.SET_REFER_ID:
	    return {
	    	...state,
	    	referId : action.referId
		}
	  case actionTypes.SET_USER_ACTIVITY:
	    return {
	    	...state,
	    	activity : action.activity
		}
	  case actionTypes.FORGET_PASSWORD:
	    return {
	    	...state,
	    	error : action.error,
	    	success : null
		}
	  case actionTypes.FORGET_PASSWORD_SUCCESS:
	    return {
	    	...state,
	    	success : action.success,
	    	error : null
		}
	  case actionTypes.RESET_PASSWORD:
	    return {
	    	...state,
	    	error : action.error,
	    	success : null
		}
	  case actionTypes.RESET_PASSWORD_SUCCESS:
	    return {
	    	...state,
	    	success : action.success,
	    	error : null
		}
	}
	
	return state;
}

export default reducer;