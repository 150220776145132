import React from 'react';
import QRCode from 'qrcode.react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	img : {
		margin : '0px'
	},
	codeWrapper: {
		lineHeight: 'normal'
	}
}));


const Trail = (props) => {

	const classes = useStyles();
	
	return (
		<Grid container>
			<Grid className={classes.codeWrapper} item xs={12} sm={12}>
				<QRCode
					size={props.size ? props.size : 128} 
					className={classes.img}
					value={process.env.REACT_APP_URL + '/' + props.id + '/' + props.code + '/5/give-award' }/> 
			</Grid>	
		</Grid>
	);
}


export default Trail;