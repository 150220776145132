import React from 'react';
import { Modal, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    orangeIconButton: {
        backgroundColor: "#DC9A27",
        borderRadius: "10px",
        margin: '10px',
        '&:hover': {
            backgroundColor: "#28DBE8"
        }
      },
      popoverContainer: {
        padding: '10px'
      },
      paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      inputContainer: {
          padding: '10px 0px 10px 0px'
      },
      titleContainer: {
          paddingBottom: '20px'
      },
      submitButtonContainer: {
          textAlign: 'right'
      },
      modalTitle: {
        textAlign: 'center'
      },
      nameField: {
          width: '100%'
      }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    }
}

function getModalId(props) {
    let id = "";
    if (props.type === "edit") {
        id = "editmodal_"+props.idEvent;
    } else if (props.type === "delete") {
        id = "deletemodal_"+props.idEvent;
    }

    return id;
}

const EditEventModal = (props) => {
	const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [eventName, setEventName] = React.useState(() => {
        if (props.data && props.data.eventName) {
            return props.data.eventName;
        }
        return "";
    });
    const [isVolunteer, setIsVolunteer] = React.useState(() => {
        if (props.data && props.data.isVolunteer) {
            if (props.data.isVolunteer === "Yes") {
                return 1;
            }
            return 0;
        }
        return "";
    });

    const handleEventNameChange = (event) => {
		setEventName(event.target.value);
	};

    const handleIsVolunteerChange = (event) => {
        setIsVolunteer(event.target.value);
    };

    const handleDeleteEvent = (event) => {
        props.deleteEvent({
            EventName: eventName,
            isVolunteer: isVolunteer,
            idEvent: props.data.id
        }, props.handleDeleteModalClose());
    }
    
    const handleEditEvent = () => {
        props.editEvent({
            EventName: eventName,
            isVolunteer: isVolunteer,
            idEvent: props.data.id
        }, props.handleEditModalClose());
    }

    const editEventModalBody = () => (
        <div style={modalStyle} className={classes.paper}>
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.modalTitle} variant="h4">
                        Edit Event
                    </Typography>
                </Grid>
            </Grid>
            <ValidatorForm method="Post" onSubmit={handleEditEvent}>
                <Grid container className={classes.inputContainer}>
                    <Grid item xs={12} sm={12}>
                        <TextValidator
                            className={classes.nameField}
                            id={"eventName"+props.data.id}
                            label="Event Name"
                            value={eventName}
                            onChange={handleEventNameChange}
                            helperText="Enter the name of the event"
                            validators = {['required']} 
                            errorMessages={['This field is required']}>
                        </TextValidator>
                    </Grid>
                </Grid>
                <Grid container className={classes.inputContainer}>
                    <Grid item xs={12} sm={6}>
                        <TextValidator
                            id={"isVolunteer"+props.data.id}
                            select
                            label="Is Volunteer"
                            value={isVolunteer}
                            onChange={handleIsVolunteerChange}
                            helperText="Is this a volunteer event?">
                            <MenuItem key={"Yes"} value={1}>
                                Yes
                            </MenuItem>
                            <MenuItem key={"No"} value={0}>
                                No
                            </MenuItem>
                        </TextValidator>
                    </Grid>
                </Grid>
                <Grid container className={classes.submitButtonContainer}>
                    <Grid item xs={12}>
                        <Button onClick={props.handleEditModalClose} className={classes.orangeIconButton} variant="contained">Cancel</Button>
                        <Button type="submit" className={classes.orangeIconButton} variant="contained">Save</Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </div>
    );
  
    const deleteEventModalBody = (data) => (
        <div style={modalStyle} className={classes.paper}>
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.modalTitle} variant="h4">
                        Delete Event
                    </Typography>
                </Grid>
            </Grid>
    
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.modalTitle} variant="body1">
                        Are you sure you want to delete {data.eventName}? The event
                        will no longer appear in the admin table and the QR code will no
                        longer be active.
                    </Typography>
                </Grid>
            </Grid>
    
            <ValidatorForm method="Post" onSubmit={handleDeleteEvent}>
                <Grid container className={classes.submitButtonContainer}>
                    <Grid item xs={12}>
                        <Button onClick={props.handleDeleteModalClose} className={classes.orangeIconButton} variant="contained">Cancel</Button>
                        <Button type="submit" className={classes.orangeIconButton} variant="contained">Confirm</Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </div>
    );

	return (
        <Modal
            id={getModalId(props.type)}
            open={props.open}
            onClose={props.handleDeleteModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            {props.type === "delete" ? deleteEventModalBody(props.data) :
            editEventModalBody(props.data)}
        </Modal>
	);
}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		editEvent: (formData) => dispatch(actions.editEvent(formData)),
        deleteEvent: (formData) => dispatch(actions.deleteEvent(formData))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(EditEventModal);