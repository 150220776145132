import React, {useEffect} from 'react';
import Layout from '../../hoc/Layout/Layout';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import  {connect } from 'react-redux';
import { Redirect} from "react-router-dom";
import * as actions from '../../store/actions/index';
import Grid from '@material-ui/core/Grid';
import friendsImg from '../../assets/images/friends.svg';
import CustomButton from '../../components/UI/Button/CustomButton';
import QRCode from 'qrcode.react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
	welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
  	},
 root : {
  	padding : '20px',
  },
  img : {
  	margin : '40px',
  	marginBottom : '5px'
  },
  rowPadding : {
  	paddingTop : '10%'
  },
  titleText: {
	  paddingTop: '25px'
  }
}));

const Friend = (props) => {

	const classes = useStyles();
	const btnBlue = '#28DBE8';
	const btnOrange = '#DC9A27';

	useEffect(() => {
		if(props.lakeId == null){
	  	props.userLoad(props.userId);
	  	}
	  });

	useEffect(() => {
		props.lakeLoad(props.lakeId);
	  	},[props.lakeId]);

	const qr_style = {};
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	if (isMobile) {
		qr_style.paddingLeft = '0px';
	}

	if(!props.token) {
		return <Redirect to="/" />;
	}
	
	return (
		<Layout>
			<Grid className={classes.bannerGrid} item xs={12}>
				<Typography className={classes.welcomeBanner} variant="body1">
				Have your friend scan your ID to be added to the app
				and to your friend count. Go to your dashboard to see
				your impact! Be sure to bookmark this page. Tap the Eagle any time to return to this page.
				</Typography>
			</Grid>
			<Grid container className={classes.root}>

				
				<Grid item xs={12} sm={12} md={6}>
					<Grid item xs={12} sm={12} md={12}>
						<CustomButton redirectLink="/do-more" btnColor={btnBlue}>Do More</CustomButton> 
					</Grid>	
					<Grid item xs={12} sm={12} md={12}>
						<CustomButton redirectLink="/dashboard" btnColor={btnOrange}>My Dashboard</CustomButton> 
					</Grid>
				</Grid>

				<Grid item xs={12} sm={12} md={6}>
					<img src={friendsImg} />
					<Typography className={classes.titleText} variant="h5">Add a friend by having them scan your QR code</Typography>
					<QRCode className = {classes.img} style={qr_style} value={process.env.REACT_APP_URL + '/home/' + props.userId }/>
					<Typography className={classes.titleText} variant="h5">Or by sending them the link below</Typography>
					<Typography className={classes.titleText} variant="h6">{process.env.REACT_APP_URL + '/home/' + props.userId }</Typography>
				</Grid>
				
			</Grid>

		</Layout>
		);
}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		lakeId : state.user_reducer.lakeId,
		lake : state.user_reducer.lake,
		token : state.auth_reducer.token
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId)),
		lakeLoad : (lakeId) => dispatch(actions.getLake(lakeId)),
		onLogOut : () => dispatch(actions.authLogout())
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Friend);