import React , { useEffect } from 'react';
import * as actions from '../../store/actions/index';
import Typography from '@material-ui/core/Typography';
import  {connect } from 'react-redux';
import Layout from '../../hoc/Layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  scanner : {
  	marginBottom : '40px'
  },
  welcomeBanner: {
	backgroundColor: '#454545',
	color: `white`,
	padding: '20px 50px 20px 50px',
	  textAlign: 'center'
  },
  bannerGrid: {
	paddingTop: '10px'
  },
  trailMessage: {
	  paddingTop: '50px'
  },
  progressBar: {
	  minHeight: '50px',
	  width: '50%'
  }
}));

const TrailProgress = (props) => {

	const classes = useStyles();

	useEffect(() => {
		if(props.trails == null) {
			props.loadTrails();
		}
		if (props.activity == null) {
			props.loadActivity(props.userId);
		}
	});

	function getTrailMessage() {
		if (props.activity && props.activity["5"] && props.trails) {
			let percentComplete = ((props.activity["5"] / props.trails.length)*100).toFixed(2)+"%";
			return (
				<span>
					You have completed {props.activity["5"]} out of {props.trails.length} trails ({percentComplete}).
				</span>
			);
		} else if (props.trails) {
			return (
				<span>
					You have completed 0 out of {props.trails.length} trails (0%).
				</span>
			);
		}
	}

	function getPercentComplete() {
		let percentComplete = 0;
		if (props.activity && props.activity["5"] && props.trails) {
			percentComplete = ((props.activity["5"] / props.trails.length)*100).toFixed(2);
		}

		return percentComplete;
	}

	
	return (
	 		<Layout>
				 <Grid container justify="center">
					<Grid className={classes.bannerGrid} item xs={12}>
						<Typography className={classes.welcomeBanner} variant="h4">
						Trail Progress
						</Typography>
					</Grid>
				</Grid>

				<Grid container className={classes.trailMessage} justify="center">
					<Grid item xs={12}>
						<Typography variant="h4">
							{getTrailMessage()}
						</Typography>
						<Grid container className={""} justify="center">
							<Grid item xs={12}>
								<progress className={classes.progressBar} value={getPercentComplete()} max={props.trails.length}></progress>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container className={classes.trailMessage} justify="center">
					<Grid item xs={12}>
						<Typography variant="h4">
							<a href="https://www.gofingerlakes.org/map" target="_blank" className={classes.orangeButton} variant="contained">Go Finger Lakes - Trails List</a>
						</Typography>
					</Grid>
				</Grid>
			</Layout>

	 	);
}

const mapStateToProps = state => {
	return {
		trails : state.trail_reducer.trails,
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token,
		activity : state.user_reducer.activity
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		loadTrails : () => dispatch(actions.getTrails()),
		loadActivity : (userId) => dispatch(actions.loadActivity(userId))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(TrailProgress);
