import React , {useEffect} from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Home from './components/Home';
import theme from './components/UI/Theme';
import Auth from './containers/User/Auth';
import Register from './containers/User/Register';
import LakeList from './containers/Lake/LakeList';
import AdminEvents from './containers/Admin/AdminEvents';
import Contact from './components/Contact';
import Volunteer from './components/Volunteer';
import Event from './components/Admin/Event';
import AdminTrails from './containers/Admin/AdminTrails';
import AdminUsers from './containers/Admin/AdminUsers';
import Admin from './components/Admin/Admin';
import PageNotFound from './components/PageNotFound';
import SampleDashboard from './components/Dashboard/SampleDashboard';
import DoMore from './components/Dashboard/DoMore';
import Award from './components/Award/Award';
import Donate from './containers/Donate/Donate';
import LakeParticipationList from './containers/Lake/LakeParticipationList';
import TrailScanner from './containers/Trail/TrailScanner';
import TrailProgress from './containers/Trail/TrailProgress';
import EventScanner from './containers/Event/EventScanner';
import Scan from './containers/Trail/Scan';
import ForgetPassword from './containers/User/ForgetPassword';
import ResetPassword from './containers/User/ResetPassword';

import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import Dashboard from './containers/Dashboard/Dashboard';
import Friend from './containers/User/Friend';
import * as actions from './store/actions/index';
import  {connect } from 'react-redux';
import history from './history';


function App(props) {

   useEffect(() => {
      props.onAutoSignIn();
  });

  return (
  	  <ThemeProvider theme = {theme}>
  	  <Router history = {history} basename={process.env.REACT_APP_PUBLIC_URL}>
  	  	 <Switch>
          <Route exact path='/'>
          	<Home />
          </Route>
          <Route path='/auth'>
          	<Auth />
          </Route>
          <Route path='/forget-password'>
            <ForgetPassword />
          </Route>
          <Route path='/reset-password/:identifier'>
            <ResetPassword />
          </Route>
          <Route exact path='/select-lake'>
            <LakeList />
          </Route>
          <Route path='/register'>
            <Register />
          </Route>
           <Route exact path='/home/:referId'>
            <Home />
          </Route>
          <Route path='/dashboard'>
            <Dashboard />
          </Route>
          <Route path='/friend'>
            <Friend />
          </Route>
          <Route path='/do-more'>
            <DoMore />
          </Route>
          <Route path='/donate'>
            <Donate />
          </Route>
            <Route path='/scan'>
            <Scan />
            </Route>
           <Route path='/trail-scanner'>
            <TrailScanner />
          </Route>
          <Route path='/trail-progress'>
            <TrailProgress />
          </Route>
          <Route path='/event-scanner'>
            <EventScanner />
          </Route>
          <Route path='/contact'>
            <Contact />
          </Route>
           <Route path='/:idObject/:qrCode/:awardType/give-award'>
            <Award />
          </Route>
          <Route path='/lake-participation'>
            <LakeParticipationList />
          </Route>          
           <Route path='/sample-dashboard'>
            <SampleDashboard />
          </Route>
          <Route path='/volunteer'>
            <Volunteer />
          </Route>
          <Route path='/event'>
            <Event />
          </Route>
          <Route path='/admin/events'>
            <AdminEvents />
          </Route>
          <Route path='/admin/trails'>
            <AdminTrails />
          </Route>
          <Route path='/admin/users'>
            <AdminUsers />
          </Route>
          <Route path='/admin'>
            <Admin />
          </Route>
          <Route>
            	<PageNotFound />
          </Route>
        </Switch>
      </Router>
      </ThemeProvider>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onAutoSignIn : () => dispatch(actions.authCheckState())
  }
}

export default connect(null, mapDispatchToProps)(App);