import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore, combineReducers , applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import authReducer from './store/reducers/auth';
import lakeReducer from './store/reducers/lake';
import userReducer from './store/reducers/user';
import trailReducer from './store/reducers/trail';
import eventReducer from './store/reducers/event';
import './assets/css/fantastic-friends.css';

const rootReducer = combineReducers({
	auth_reducer : authReducer,
	lake_reducer : lakeReducer,
	user_reducer : userReducer,
	trail_reducer : trailReducer,
  event_reducer: eventReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer,composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}><App /></Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
