import React, {useRef, useLayoutEffect} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Themes begin
am4core.useTheme(am4themes_myTheme);
am4core.useTheme(am4themes_animated);
// Themes end
	
function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#239CCB"),
      am4core.color("#ffffff"),
    ];
  }
}


const ActivityGraph = (props) => {

	useLayoutEffect(() => {

    let chart = am4core.create(props.name, am4charts.XYChart);
   
	   // Add data
	chart.data = [{
	  "type": props.name,
	  "value": props.value > 100 ? 100 : props.value
	}];

   // Create axes
	var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
	categoryAxis.dataFields.category = "type";
	categoryAxis.renderer.grid.template.location = 0;
	categoryAxis.renderer.grid.template.strokeWidth = 0;

	var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	valueAxis.renderer.inside = true;
	valueAxis.min = 0;
	valueAxis.max = 100;
	valueAxis.renderer.labels.template.disabled = true;
	valueAxis.renderer.grid.template.strokeWidth = 0;

// Create series
	function createSeries(field, name) {
	  
	  // Set up series
	  var series = chart.series.push(new am4charts.ColumnSeries());
	  series.name = name;
	  series.dataFields.valueY = field;
	  series.dataFields.categoryX = "type";
	  series.sequencedInterpolation = true;
	  
	  // Make it stacked
	  series.stacked = true;
	  
	  // Configure columns
	  series.columns.template.width = am4core.percent(55);
	  //series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
	  series.fill = am4core.color(props.color);
	  series.stroke = am4core.color('#728187');

	  // Add label
	  // var labelBullet = series.bullets.push(new am4charts.LabelBullet());
	  // labelBullet.label.text = "{valueY}";
	  // labelBullet.locationY = 0.5;
	  // labelBullet.label.hideOversized = true;
	  
	  return series;
	}

	createSeries("value", "");

	// Legend
	//chart.legend = new am4charts.Legend();


    return () => {
      chart.dispose();
    };
  }, []);


	return (
			<div id={props.name} style={{ width: "100%", height: "240px", }}></div>  
		);
}

export default ActivityGraph;