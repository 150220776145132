import React , { useEffect } from 'react';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridOverlay  } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon  from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Typography from '@material-ui/core/Typography';
import Event from './Event';
import EditEventModal from '../../components/UI/EditEventModal';
import { Modal } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    '& .ant-empty-img-1': {
      fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
      fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
    },
  },
  label: {
    marginTop: theme.spacing(1),
  },
  orangeIconButton: {
    backgroundColor: "#DC9A27",
    borderRadius: "10px",
    margin: '10px',
    '&:hover': {
        backgroundColor: "#28DBE8"
  },
  popoverContainer: {
    padding: '10px'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inputContainer: {
      padding: '10px 0px 10px 0px'
  },
  titleContainer: {
      paddingBottom: '20px'
  },
  submitButtonContainer: {
      textAlign: 'right'
  },
  modalTitle: {
    textAlign: 'center'
  }
},
}));

function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>No Rows</div>
    </GridOverlay>
  );
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
  }
}

function GetColumns(props) {

const classes = useStyles();
const [editModalOpen, setEditModalOpen] = React.useState(false);
const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
const [popoverOpen, setPopoverOpen] = React.useState(false);

const handleSetPopoverOpen = (idEvent) => {
  setPopoverOpen(idEvent);
};

const handleSetPopoverClose = () => {
  setPopoverOpen(null);
};

const handleEditModalOpen = (event) => {
  setEditModalOpen(event.row.id);
};

const handleEditModalClose = () => {
  setEditModalOpen(null);
};

const handleDeleteModalOpen = (event) => {
  setDeleteModalOpen(event.id);
};

const handleDeleteModalClose = () => {
  setDeleteModalOpen(null);
};

  const columns = [
    {
        field: 'id',
        headerName: 'ID',
        type: 'number',
        sortable: true,
        width: 70
    },
    {
        field: 'eventName',
        headerName: 'Event Name',
        sortable: true,
        width: 300
    },
    {
        field: 'qrCode',
        headerName: 'QR Code',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 200,
        renderCell: (event) => {
            return (
              <Grid align="center" justify="center">
                <Event
                  name={event.row.eventName}

                  id={event.row.id}
                  code={event.row.qrCode}
                  key={event.row.id}/>
              </Grid>
            );
        }
    },
    {
      field: 'isVolunteer',
      headerName: 'Volunteer?',
      Sortable: true,
      width: 125,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      sortable: false,
      width: 300,
      renderCell: (event) => {

          return (
            <Grid key={event.row.eventName} container justify="flex-end">
              <Grid item xs={12}>
                <Button aria-describedby={"popover-"+event.row.id} onClick={handleSetPopoverOpen.bind(this, event.row.id)} className={classes.orangeIconButton} variant="contained">
                  <ZoomInIcon/>
                </Button>
                  <Popover
                    id={"popover-"+event.row.id}
                    open={popoverOpen && popoverOpen == event.row.id ? true : false}
                    onClose={handleSetPopoverClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 200, left: 600 }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}>
                    <Grid style={{width:'600px',height:'600px', textAlign: 'center'}} container>
                      <Grid
                        container
                        spacing={0}
                        align="center"
                        justify="center"
                        direction="column">
                        	<Typography style={{paddingBottom:'20px'}} variant="h6">
                        		{event.row.eventName}
                        	</Typography>
                          <Event
                            size={256}
                            name={event.row.eventName}
                            //eventclicked={(event_id)=>props.onEventClick(event_id,props.userId)}
                            id={event.row.id}
                            code={event.row.qrCode}
                            key={event.row.id} />
                      </Grid>
                    </Grid>

                  </Popover>
                <Button onClick={handleEditModalOpen.bind(this, event)} className={classes.orangeIconButton} variant="contained">
                  <EditIcon/>
                </Button>
                <EditEventModal
                  handleEditModalClose={handleEditModalClose}
                  data={event.row}
                  value={event.row.eventName}
                  open={editModalOpen && editModalOpen == event.id ? true : false}
                  type="edit">
                </EditEventModal>

                <Button onClick={handleDeleteModalOpen.bind(this, event)} className={classes.orangeIconButton} variant="contained">
                  <DeleteIcon/>
                </Button>
                <EditEventModal
                  handleDeleteModalClose={handleDeleteModalClose}
                  data={event.row}
                  value={event.row.eventName}
                  open={deleteModalOpen && deleteModalOpen == event.id ? true : false}
                  type="delete">
                </EditEventModal>
              </Grid>
            </Grid>
          );
      }
    }
  ];

  return columns;
}


const EventList = (props) => {

  const classes = useStyles();

	useEffect(() => {
	  if(props.events == null) {
	  		props.onLoad();
	  	}
	});

  let rows = [];

	if(props.events !=null)
	{
		props.events.forEach(function(event) {
      rows.push({id: event.idEvent, eventName: event.EventName, qrCode: event.qrCode, isVolunteer: event.IsVolunteer ? "Yes" : "No"});
    });

		return (
			<Grid container justify="center">
				<div style={{ height: "800px", width: '100%' }}>

          <DataGrid container
						pageSize={10}
						rowHeight={200}
						rows={rows}
						columns={GetColumns(props)}/>
				</div>
			</Grid>
		);
	}

    return (
      <DataGrid
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        rows={[]}
        columns={GetColumns(props)}/>
    );
}

const mapStateToProps = state => {
	return {
		events : state.event_reducer.events,
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		onLoad : () => dispatch(actions.getEvents())
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(EventList);
