import React from 'react'
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';


const LakeCard = (props) => {
	return (
			<React.Fragment>
				<Grid item xs={2}/>
				<Grid item xs={3}><img src={process.env.REACT_APP_URL + '/images/lakes/' + props.img}/></Grid>
		        <Grid item xs={1}/>
		        <Grid item xs={3}>
				<Typography variant="h5" > {props.name} </Typography>
		       		 		<Typography variant="caption" >Elevation : {props.elevation} </Typography><br/>
					 		<Typography variant="caption" >Area : {props.area}  </Typography><br/>
					 		<Typography variant="caption" >Lenght :  {props.length} </Typography><br/>
					 		<Typography variant="caption" >Maximum Width : {props.maxwidth} </Typography><br/>
					 		<Typography variant="caption" >Maximum Depth : {props.maxdepth} </Typography><br/>
					 		<Typography variant="caption" >Thermocline : {props.thermocline}  </Typography>
				</Grid>
				<Grid item xs={3}/>
			</React.Fragment>
			);
}

export default LakeCard;