import React, { useEffect }  from 'react';
import Typography from '@material-ui/core/Typography';
import UserList from '../User/UserList';
import Layout from '../../hoc/Layout/Layout';
import  {connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import * as actions from '../../store/actions/index';
import CreateUserModal from '../../components/UI/CreateUserModal';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
	modalTitle: {
        textAlign: 'center'
    },
    welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
  	},
    userListContainer: {
        paddingTop: '20px'
    },
    orangeButton: {
        backgroundColor: "#DC9A27",
        borderRadius: "10px",
        margin: '11px',
        '&:hover': {
            backgroundColor: "#28DBE8"
        }
    },
    newUserButtonWrapper: {
        textAlign: 'right',
        paddingTop: '20px'
    },
    titleWrapper: {
        textAlign: 'left',
        paddingTop: '20px'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    inputContainer: {
        padding: '10px 0px 10px 0px'
    },
    titleContainer: {
        paddingBottom: '20px'
    },
    submitButtonContainer: {
        textAlign: 'right'
    }
}));

const AdminUsers = (props) => {
	const classes = useStyles();
	const [modalStyle] = React.useState(getModalStyle);
    const [createUserModalOpen, setCreateUserModalOpen] = React.useState(false);
    const [userName, setUserName] = React.useState(false);

    useEffect(() => {
        if(props.users == null)
        {
            props.loadUsers();
        }
    });

    const handleCreateUserModalOpen = () => {
        setCreateUserModalOpen(true);
    };

    const handleCreateUserModalClose = () => {
        setUserName("");
        setCreateUserModalOpen(false);
    }

	return (
			<Layout isAuth={props.token}>
				<Grid className={classes.bannerGrid} item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h4">
					View and Edit Users
					</Typography>
				</Grid>

                <Grid container justify="flex-end">
                    <Grid className={classes.newUserButtonWrapper} item xs={12} sm={6}>
                        <Typography className={classes.titleWrapper} variant="h5">
                            Users
                        </Typography>

                        <CreateUserModal
                            handleCreateUserModalClose={handleCreateUserModalClose}
                            submitAction={props.submitNewUser}
                            open={createUserModalOpen ? true : false}>
                        </CreateUserModal>

                    </Grid>

                    <Grid className={classes.newUserButtonWrapper} item xs={12} sm={6}>
                        <Button size="large" onClick={handleCreateUserModalOpen} className={classes.orangeButton} variant="contained">Create New User</Button>
                    </Grid>

                </Grid>

                <Grid container className={classes.userListContainer}>
                    <Grid item xs={12}>
				        <UserList users={props.users}/>
                    </Grid>
                </Grid>
			</Layout>
		);

}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token,
        users : state.user_reducer.users
    }
}

const mapDispatchToProps = dispatch => {
	return {
		submitNewUser : (formData, callback) => dispatch(actions.submitNewUser(formData, callback)),
        loadUsers: () => dispatch(actions.getUsers())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
