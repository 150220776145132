import React from 'react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';
import {
    Grid,
    Typography,
    Modal,
    Button,
    MenuItem,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    orangeIconButton: {
        backgroundColor: "#DC9A27",
        borderRadius: "10px",
        margin: '10px',
        '&:hover': {
            backgroundColor: "#28DBE8"
        }
      },
      popoverContainer: {
        padding: '10px'
      },
      paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      inputContainer: {
          padding: '10px 0px 10px 0px'
      },
      titleContainer: {
          paddingBottom: '20px'
      },
      submitButtonContainer: {
          textAlign: 'right'
      },
      modalTitle: {
        textAlign: 'center'
      },
      nameField: {
          width: '100%'
      }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    }
}

const EditUserModal = (props) => {

    const initialState = {
        id: props.user.id,
        FirstName: props.user.firstName,
        LastName: props.user.lastName,
        Email: props.user.email,
        Password: props.user.password,
        ConfirmPassword: props.user.password,
        Enabled: props.user.enabled,
        isAdmin: props.user.isAdmin,
    };

	const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [formData, setFormData] = React.useState({
        id: props.user.id,
        FirstName: props.user.firstName,
        LastName: props.user.lastName,
        Email: props.user.email,
        Password: props.user.password,
        ConfirmPassword: props.user.password,
        Enabled: props.user.enabled,
        isAdmin: props.user.isAdmin,
    });

    const handleUserDataChange = (event) => {
        formData[event.target.name] = event.target.value;
		setFormData({...formData});
	};

    const handleEditUser = () => {
        props.submitAction(formData, function(result, error_message) {
            if(result) {
                props.handleEditUserModalClose();
            } else {
                alert(error_message);
            }
        });
    }

    function handleEditUserModalClose () {
		setFormData(initialState);
        props.handleEditUserModalClose();
    }

	return (
        <Modal
            id={"editModal"}
            open={props.open}
            onClose={handleEditUserModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div style={modalStyle} className={classes.paper}>
                <Grid container className={classes.titleContainer}>
                    <Grid item xs={12}>
                        <Typography className={classes.modalTitle} variant="h4">
                            Edit User
                        </Typography>
                    </Grid>
                </Grid>
                <ValidatorForm method="Post" onSubmit={handleEditUser}>
                    <Grid container className={classes.inputContainer}>
                        <Grid item xs={12} sm={12}>
                            <TextValidator
                                className={classes.nameField}
                                id="FirstName"
                                label={formData.FirstName && formData.FirstName.length ? "" : "First Name"}
                                name="FirstName"
                                value={formData.FirstName}
                                onChange={handleUserDataChange}
                                helperText="Enter the users first name"
                                validators = {['required']}
                                errorMessages={['This field is required']}>
                            </TextValidator>

                            <TextValidator
                                className={classes.nameField}
                                id="LastName"
                                label={formData.LastName && formData.LastName.length ? "" : "Last Name"}
                                name="LastName"
                                value={formData.LastName}
                                onChange={handleUserDataChange}
                                helperText="Enter the users last name"
                                validators = {['required']}
                                errorMessages={['This field is required']}>
                            </TextValidator>

                            <TextValidator
                                className={classes.nameField}
                                id="Email"
                                label={formData.Email && formData.Email.length ? "" : "Email Address"}
                                value={formData.Email}
                                name="Email"
                                onChange={handleUserDataChange}
                                helperText="Enter the user's email address"
                                validators = {['required']}
                                errorMessages={['This field is required']}>
                            </TextValidator>

                            <SelectValidator
                                className={classes.nameField}
                                id="Enabled"
                                label="Enabled"
                                value={formData.Enabled}
                                name="Enabled"
                                onChange={handleUserDataChange}
                                helperText="Allow login access for the user"
                                validators = {['required']}
                                errorMessages={['This field is required']}>
                                <MenuItem value="0">No</MenuItem>
                                <MenuItem value="1">Yes</MenuItem>
                            </SelectValidator>

                            <SelectValidator
                                className={classes.nameField}
                                id="isAdmin"
                                label="Is Admin?"
                                value={formData.isAdmin}
                                name="isAdmin"
                                onChange={handleUserDataChange}
                                helperText="Allow admin access for the user"
                                validators = {['required']}
                                errorMessages={['This field is required']}>
                                <MenuItem value="0">No</MenuItem>
                                <MenuItem value="1">Yes</MenuItem>
                            </SelectValidator>

                        </Grid>
                    </Grid>
                    <Grid container className={classes.submitButtonContainer}>
                        <Grid item xs={12}>
                            <Button onClick={handleEditUserModalClose} className={classes.orangeIconButton} variant="contained">Cancel</Button>
                            <Button type="submit" className={classes.orangeIconButton} variant="contained">Save</Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </div>
        </Modal>
	);
}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token
 	}
}

export default connect(mapStateToProps)(EditUserModal);
