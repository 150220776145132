import React from 'react';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../UI/Button/CustomButton';
import Layout from '../../hoc/Layout/Layout';
import  {connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
  	}
}));

const Event = (props) => {
	const classes = useStyles();
	const btnBlue = '#28DBE8';

	return (
			<Layout isAuth={props.token}>
				<Grid className={classes.bannerGrid} item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h6">
					Attending an event? Get the event's QR code from the event coordinator 
                    and use the Scan button below to scan the code and record a touchpoint!
					</Typography>
				</Grid>
				
				<CustomButton className="dash-custom-button" redirectLink="/event-scanner" btnColor={btnBlue}>Scan QR Code</CustomButton>
		
		<Grid className={classes.buttonContainer} item xs={12}>
		{/*copied code from contact us buttons and changed to events opportunies al*/}
					<a href="https://www.fingerlakesmuseum.org/events" target="_blank" className={classes.orangeButton} variant="contained">Events at the Finger Lakes Museum</a>
				</Grid>
			</Layout>
		);

}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token
 	}
}


export default connect(mapStateToProps)(Event);