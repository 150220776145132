import * as actionTypes from './actionTypes';
import axios from 'axios';
import history from '../../history';
import {getEvents} from "./event";

export const setUserLake = (id) => {
	return {
		type : actionTypes.SET_USER_LAKE,
		id : id
	}
}

export const setReferId = (referId) => {
	return {
		type : actionTypes.SET_REFER_ID,
		referId : referId
	}
}

export const removeLake = () => {
	return {
		type : actionTypes.REMOVE_LAKE
	}
}


export const setLake = (lake) => {
	return {
		type : actionTypes.GET_LAKE,
		lake : lake
	}
}

export const setUser = (user,lakeId) => {
	return {
		type : actionTypes.SET_USER_DATA,
		user : user,
		lakeId : lakeId
	}
}

export const setUsers = (users) => {
	return {
		type : actionTypes.SET_USERS,
		users : users
	}
}

export const setActivity = (activity) => {
	return {
		type : actionTypes.SET_USER_ACTIVITY,
		activity : activity
	}
}

export const forgetPasswordFail = (error) => {
	return {
		type : actionTypes.FORGET_PASSWORD,
		error : error
	}
}

export const forgetPasswordSuccess = (message) => {
	return {
		type : actionTypes.FORGET_PASSWORD_SUCCESS,
		success : message
	}
}

export const resetPasswordFail = (error) => {
	return {
		type : actionTypes.RESET_PASSWORD,
		error : error
	}
}

export const resetPasswordSuccess = (message) => {
	return {
		type : actionTypes.RESET_PASSWORD_SUCCESS,
		success : message
	}
}

export const registerSuccess = (user) => {
	return {
		type : actionTypes.REGISTER_SUCCESS,
		user : user
	}
}

export const registerFailure = (error) => {
	return {
		type : actionTypes.REGISTER_FAILURE,
		error : error
	}
}

export const register = (formData) => {
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/user', {
    		FirstName: formData.FirstName,
			LastName: formData.LastName,
			Email : formData.Email,
			Password : formData.Password,
			ConfirmPassword : formData.ConfirmPassword,
			idCustomField : formData.idCustomField,
			idRefer : formData.idRefer
		}).then(response=> {
			if(response.data.success==true)
			{
				dispatch(registerSuccess( response.data.user));
				history.push('/auth');
			} else {
				dispatch(registerFailure(response.data.message));
			}
		})
		.catch(err => {
			console.log(err);
		});
	}
}

export const createUser = (formData) => {
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/user', {
    		FirstName: formData.FirstName,
			LastName: formData.LastName,
			Email : formData.Email,
			Password : formData.Password,
			ConfirmPassword : formData.ConfirmPassword,
			idCustomField : formData.idCustomField,
			idRefer : formData.idRefer
		}).then(response=> {
			if(response.data.success)
			{
				console.log(response.data);
			} else {
				// alert(response.data.message);
			}
		})
		.catch(err => {
			console.log(err);
		});
	}
}

export const submitNewUser = (formData, callback) => {
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/user', {
    		FirstName: formData.FirstName,
			LastName: formData.LastName,
			Email : formData.Email,
			Password : formData.Password,
			ConfirmPassword : formData.ConfirmPassword
		}).then(response=> {
			if(response.data.success)
			{
				dispatch(getUsers());
				callback && callback(true);
			} else {
				callback && callback(false, response.data.message);
			}
		})
		.catch(err => {
			console.log(err);
		});
	}
}

export const submitEditUser = (formData, callback) => {
	return dispatch => {
		axios.post(process.env.REACT_APP_API_URL + '/user/'+formData.id, {
			idUser: formData.id,
    		FirstName: formData.FirstName,
			LastName: formData.LastName,
			Email : formData.Email,
			Enabled : formData.Enabled,
			isAdmin : formData.isAdmin,
		}).then(response=> {
			if(response.data.success)
			{
				dispatch(getUsers());
				callback && callback(true);
			} else {
				callback && callback(false, response.data.message);
			}
		})
		.catch(err => {
			console.log(err);
		});
	}
}

export const submitDeleteUser = (idUser, callback) => {
	return dispatch => {
		axios.delete(process.env.REACT_APP_API_URL + '/user/'+idUser, {
    		idUser: idUser
		}).then(response=> {
			if(response.data.success)
			{
				dispatch(getUsers());
				callback && callback(true);
			} else {
				callback && callback(false, response.data.message);
			}
		})
		.catch(err => {
			console.log(err);
		});
	}
}

export const getLake = (id) => {
	return dispatch => {
		axios.get(process.env.REACT_APP_API_URL + '/lake/' + id)
			.then(response=> {
				if(response.data.success==true)
				{
				dispatch(setLake(response.data.lake))
				}
			})
			.catch(err => {
				console.log(err);
			});

	}
}

export const getUser = (id) => {
	return dispatch => {
		axios.get(process.env.REACT_APP_API_URL + '/user/' + id)
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(setUser(response.data.user,response.data.lakeId))
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const getUsers = () => {
	return dispatch => {
		axios.get(process.env.REACT_APP_API_URL + '/users')
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(setUsers(response.data.users))
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const loadActivity = (id) => {
	return dispatch => {
		axios.get(process.env.REACT_APP_API_URL + '/activity/' + id)
			.then(response=> {
				if(response.data.success==true)
				{
				dispatch(setActivity(response.data.activity))
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const onForgetPassword = (email) => {
	return dispatch => {
			axios.get(process.env.REACT_APP_API_URL + '/forget-password/' + email)
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(forgetPasswordSuccess(response.data.message))
				} else if(response.data.success ==false) {
					dispatch(forgetPasswordFail(response.data.message));
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const onResetPassword = (identifier,password,confirmpassword) => {
	return dispatch => {
			axios.post(process.env.REACT_APP_API_URL + '/reset-password', {
    		Identifier: identifier, Password: password , ConfirmPassword : confirmpassword})
			.then(response=> {
				if(response.data.success==true)
				{
					dispatch(resetPasswordSuccess(response.data.message))
				} else if(response.data.success ==false) {
					dispatch(resetPasswordFail(response.data.message));
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}
