import React, {useRef, useLayoutEffect} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_moonrisekingdom from "@amcharts/amcharts4/themes/moonrisekingdom";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Typography from '@material-ui/core/Typography';
import { Redirect} from "react-router-dom";
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';

// Themes begin
am4core.useTheme(am4themes_myTheme);
am4core.useTheme(am4themes_animated);
// Themes end

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#239CCB"),
      am4core.color("#ffffff"),
    ];
  }
}

const LakeParticipation = (props) => {

  

	const chart = useRef(null);
    const Maroon = '#A20B0B'; 
    
	useLayoutEffect(() => {

    var iconPath = props.svgCode;

    let x = am4core.create(props.name, am4charts.SlicedChart);
   
    
    x.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    x.paddingLeft = 25;

    x.data = [{
    "name": "",
    "value": 100 - props.participation,
    "disabled":true
    }, {
        "name": "",
        "value": props.participation
    }];


    let series = x.series.push(new am4charts.PictorialStackedSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.dataItems.template.locations.dateX = 0.7;
    series.alignLabels = true;

    // this makes only A label to be visible
    series.labels.template.propertyFields.disabled = "disabled";
    series.ticks.template.propertyFields.disabled = "disabled";

    series.maskSprite.path = iconPath;
    series.ticks.template.locationX = 1;
    series.ticks.template.locationY = 0;
    series.stroke = am4core.color("#000000");
    series.strokeWidth = 1;

    series.labelsContainer.width = 100;
    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  if(!props.token) {
		return <Redirect to="/auth" />;
	}

	return (
        <React.Fragment>
			<div style={{
         backgroundImage: `url("`+ process.env.REACT_APP_URL + `/images/participation-bg.svg")`,
         backgroundRepeat: 'no-repeat',color:'black',  height: "400px", overflow: 'hidden', 
         backgroundSize: '100% 100%'
      }}>
            <div id={props.name} style={{ width: "100%", paddingTop: '30px' , height: "320px",marginLeft : '30px'}}></div>  
            <Typography variant="h5">{props.name}</Typography>
        </div>
        <Typography variant="body2" style={{ 'marginTop' : '20px', 'color' : Maroon, 'fontWeight' : 'bold'}}>
          Swipe to see how other lake  <br/> participation compares
        </Typography>
		</React.Fragment> 	 	
        );
}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token,
		user: state.user_reducer.user
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(LakeParticipation);
