import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    oval : {
     ...theme.shape.oval
  }
}));

const CustomButtton = (props) => {

	let propsClass = "";
	if (props.className) {
		propsClass = " "+props.className;
	}

	let buttonStyle = {
		backgroundColor: props.btnColor
	}

	if (props.buttonWidth) {
		buttonStyle.width = props.buttonWidth;
	}

	const classes = useStyles();

			return (
					 <RouterLink style={{ textDecoration: 'none' }} to={props.redirectLink}> <Button variant="contained" style={buttonStyle} className={classes.oval+propsClass} disableElevation>
	        			{props.children}</Button></RouterLink>
				);
}

export default CustomButtton;