import React from 'react';
import Layout from '../hoc/Layout/Layout';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
  	}
}));

const PageNotFound = () => {
	const classes = useStyles();

	return (
		<Layout>
			<Grid className={classes.bannerGrid} item xs={12}>
				<Typography className={classes.welcomeBanner} variant="h5">Page Not Found</Typography>
			</Grid>
		</Layout>
	);
}

export default PageNotFound;