import React from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Lake = (props) => {
	return(
			<React.Fragment>
			<Grid item xs={4}>
			<Box onClick={()=>props.lakechange(props.id)}>
			<img src={process.env.REACT_APP_URL + '/images/lakes/' + props.image} />
			<Typography varaint="h5">{props.name}</Typography>
			</Box>
			</Grid>
			</React.Fragment>
		);
}


export default Lake;
