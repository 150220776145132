import React , { useEffect, useState } from 'react';
import Lake from '../../components/Lake/Lake';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';
import Layout from '../../hoc/Layout/Layout';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import eagleImg from '../../assets/images/eagle.svg';
import backImg from '../../assets/images/back.svg';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink, useParams} from "react-router-dom";
import { Redirect} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  rowPadding : {
  	paddingTop : '10%'
  },
  lakeGrid: {
	  paddingTop: '3%'
  },
  welcomeBanner: {
	backgroundColor: '#454545',
	color: `white`,
	padding: '20px 50px 20px 50px',
	  textAlign: 'center'
  },
  bannerGrid: {
	paddingTop: '10px'
  }
}));


const LakeList = (props) => {

	const classes = useStyles();
	
	 useEffect(() => {
	  	if(props.lakes == null)
	  	{
	  
	  		props.onLoad();
	  	}
	  });

	
	 if(props.token !=null)
	 {
	 	return <Redirect to="/dashboard" />;
	 }
	
	if(props.lakeId !=null)
	 {
	 	return <Redirect to="/register" />;
	 }

	let lakesList = <p>Loading lakes...</p>;

	if(props.lakes !=null)
	{
		lakesList = (
			props.lakes.map(lake=>{
				return <Lake lakechange={(id)=>props.onLakeChange(id)} id={lake.idLake} key={lake.idLake} name={lake.LakeName} image= {lake.Img} />
			})
		);
	}

	return(
		   <Layout footer={true} showLake={false}>
			   <Grid className={classes.bannerGrid} item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h5">
					Pick a Lake
					</Typography>
				</Grid>
				<Grid className={classes.lakeGrid} container spacing={3}>
					{lakesList}
				</Grid>
			</Layout>
		);
}

const mapStateToProps = state => {
	return {
		error: state.lake_reducer.error,
		lakes : state.lake_reducer.lakes,	
		lakeId : state.user_reducer.lakeId,
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		onLoad : () => dispatch(actions.getLakes()),
		onLakeChange : (id) => dispatch(actions.setUserLake(id))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(LakeList);

