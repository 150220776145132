import React, {Component} from 'react';
import Layout from '../../hoc/Layout/Layout';
import { Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import { Redirect} from "react-router-dom";	
import Button from '@material-ui/core/Button';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';
import LakeCard from '../../components/Lake/LakeCard';
import ReCAPTCHA from "react-google-recaptcha";

class Register extends Component {

	
	state = {
		formData : {
			FirstName : '',
			LastName : '',
			Email : '',
			Password : '',
			idCustomField : 0,
			idRefer : 0
		},
		submitted : false,
		captcha : false
	}
	
	componentDidMount () {
			this.props.onLoad(this.props.lakeId)
	}


	handleChange = (event) => {
		const {formData} = this.state;
		formData[event.target.name] = event.target.value;
		this.setState({
		...this.state.submitted,
        formData : formData
      })
	}

	handleSubmit = (event) => {
			const captcha = this.state.captcha;
			
			if(!captcha){
				alert('Captcha is required');
				return;	
			}
			const {formData} = this.state;

			formData['idCustomField'] = this.props.lakeId;
			formData['idRefer'] = this.props.referId;
			this.setState({
			...this.state.submitted,
	        formData : formData
	      })
			this.props.onRegister(this.state.formData)
	}

	onCaptchaChange = (value) => {
		this.setState({
	  	 	captcha : true
	  	})
	}


	render() {

	
	const {formData, submitted} = this.state;

	const inpuptStyle = {
 	width : '80%',
 	marginBottom: '20px'
 	}

	const btnOval = {
		      width : '200px',
		      height: '100px',
		      borderRadius: '100px / 50px',
		      margin : '25px 5px 20px 5px',
		      textTransform : 'none',
		      border : '1px solid #707070'
		  }

   const btnOvalHeading = {
		      width : '200px',
		      height: '100px',
		      borderRadius: '100px / 50px',
		      margin : '25px 5px 20px 5px',
		      textTransform : 'none',
		      border : '1px solid #707070',
		      color : '#DD233C',
		      fontWeight : 'bold'
		  }

	const welcomeBanner = {
			backgroundColor: '#454545',
			color: `white`,
		  padding: '20px 50px 20px 50px',
		  textAlign: 'center'
	  }
	const bannerGrid = {
		  paddingTop: '10px'
	  }
	

	if(this.props.userId !=null)
	 {
	 	return <Redirect to="/dashboard" />;
	 }
	

	if(this.props.lakeId == null)
	 {
	 	return <Redirect to="/select-lake" />;
	 }


	let errMsg = null;

	if(this.props.error) {
		errMsg = (
				<p>{this.props.error}</p>
			);
	}

	let lakeCard = <p>Loading Lake Info..</p>;

	if(this.props.lake != null){
		lakeCard = (
			<LakeCard 
				name= {this.props.lake.LakeName}
				elevation =  {this.props.lake.Elevation}
				area = {this.props.lake.Area}
				length = {this.props.lake.Length}
				maxwidth = {this.props.lake.maximumWidth}
				maxdepth = {this.props.lake.maximumDepth}
				thermocline = {this.props.lake.Thermocline}
				img = {this.props.lake.Img}
			/>
			);
	}
	
	return (
			<Layout>
				<Grid container>
					<Grid style={bannerGrid} item xs={12}>
						<Typography style={welcomeBanner} variant="h5">
							Tell Us About You
						</Typography>
					</Grid>

					<Grid item xs={12} style={{padding : '15px'}} >
					<ValidatorForm ref="form" method="Post" onSubmit={this.handleSubmit}>
					
					<Typography variant="body1" color="error">{errMsg}</Typography>
					<Grid item xs={12} >
					<TextValidator
					 style={inpuptStyle}
					 variant="outlined"
			         label="First Name"
			         onChange={ this.handleChange }
			         name="FirstName"
			          value = {formData.FirstName}
			         validators = {['required']} 
			         errorMessages={['this field is required']}
			        />
			        <TextValidator
			         style={inpuptStyle}
					 variant="outlined"
			         label="Last Name"
			         onChange={ this.handleChange }
			         name="LastName"
			          value = {formData.LastName}
			         validators = {['required']} 
			         errorMessages={['this field is required']}
			        />

					<TextValidator
					 style={inpuptStyle}
					 variant="outlined"
			         label="Email"
			         onChange={ this.handleChange }
			         name="Email"
			          value = {formData.Email}
			         validators = {['required','isEmail']} 
			         errorMessages={['this field is required','email is not valid']}
			        />
			        <TextValidator
			          style={inpuptStyle}
					 variant="outlined"
			          type = "password"
			          label="Password"
			         onChange={ this.handleChange }
			         name="Password"
			          value = {formData.Password}
			         validators = {['required']} 
			         errorMessages={['this field is required']}
			        />

			        <TextValidator
			           style={inpuptStyle}
					 variant="outlined"
			          type = "password"
			          label="Confirm Password"
			         onChange={ this.handleChange }
			         name="ConfirmPassword"
			          value = {formData.ConfirmPassword}
			         validators = {['required']} 
			         errorMessages={['this field is required']}
			        />
		       		</Grid>
		       		<Grid container xs={12} justify="center">
						<ReCAPTCHA
							sitekey = "6LcT7K8aAAAAAOc-5_0QDWvuTyU97wTfBt2zDYy8"
							onChange= { this.onCaptchaChange }
						/>
		       		</Grid>
					<Grid item xs={12} >
		       		 <Button style = {btnOval} type="submit" disabled = {submitted}  variant="contained" color="secondary" disableElevation>
		       		 Next
			        </Button>
			        </Grid>
			        <Grid container style={{marginTop: "30px"}} >
		       		 		{ lakeCard }
		       		 </Grid>
			         </ValidatorForm>
			         </Grid>
		        </Grid>
			</Layout>
		);
	}
}

const mapStateToProps = state => {
	return {
		lakeId: state.user_reducer.lakeId,
		lake : state.user_reducer.lake,
		userId : state.auth_reducer.userId,
		referId : state.user_reducer.referId,
		error: state.user_reducer.error
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		onRegister : (formData) => dispatch(actions.register(formData)),
		onLoad : (id) => dispatch(actions.getLake(id))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Register);