import React , { useState } from 'react';
import * as actions from '../../store/actions/index';
import Typography from '@material-ui/core/Typography';
import  {connect } from 'react-redux';
import QrReader from 'react-qr-reader';
import Layout from '../../hoc/Layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  scanner : {
  	marginBottom : '40px'
  },
  welcomeBanner: {
	backgroundColor: '#454545',
	color: `white`,
	padding: '20px 50px 20px 50px',
	  textAlign: 'center'
  },
  bannerGrid: {
	paddingTop: '10px'
  }
}));

const EventScanner = (props) => {

	//const [qrResult, qrResultSet] = useState('No result');

	const classes = useStyles();

	function handleScan (data){
		console.log('scan event', data);
    	if (data) {
    		if(data.includes('fantastic-friends'))
    		{
    			window.location.href = data;
    		} else {
    		    var lastIndex = data.lastIndexOf(" ");
    		    const event_id = data.slice(lastIndex+1);
    		    window.location.href = process.env.REACT_APP_URL + '/' + event_id + '/6/give-award' ;
    		}
	   	 }
	  }

	function handleError (err){
	    console.error('Error', err)
	  }

	 return (
	 		<Layout footer={true}>
				 <Grid className={classes.bannerGrid} item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h6">
					Scan a valid Event QR Code below to get a point!
					</Typography>
				</Grid>
				<QrReader
					delay={300}
					onError={handleError}
					onScan={handleScan}
					style={{ width: '100%' }}
				/>
			</Layout>

	 	);
}

const mapStateToProps = state => {
	return {
		events : state.event_reducer.events,
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		onEventClick : (idEvent,idUser) => dispatch(actions.giveUserAward(idEvent,6,idUser))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(EventScanner);
