import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    oval : {
     ...theme.shape.oval,
	 fontWeight: 'bold',
	 color: 'white'
  }
}));

const DonateButtton = (props) => {

	const classes = useStyles();

	return (
		<Button variant="contained" href={props.redirectLink} target="_blank" style={{ backgroundColor :  props.btnColor }} className={classes.oval} disableElevation>
			{props.children}
		</Button>
	);
}

export default DonateButtton;