import React, {useState} from 'react';
import Layout from '../../hoc/Layout/Layout';
import { Grid } from '@material-ui/core';
import { useParams} from "react-router-dom";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    btnOval : {
	     ...theme.shape.oval
	  }
	}));

const ResetPassword = (props) => {

	const [password,setPassword] = useState(null);

	const [confirmpassword, setConfirmPassword] = useState(null);

	const [submitted,setSubmitted] = useState(false);
	
	let { identifier } = useParams();

	const classes = useStyles();

	let errMsg = null;

	if(props.error) {
		errMsg = (
				<p>{props.error}</p>
			);
	}

	let successMsg = null;

	if(props.success) {
		successMsg = (
				<p>{props.success}</p>
			);
	}	

	const inputStyle = {
 	width : '60%',
 	marginBottom: '20px'
 	}

	const handleChange = (event) => {
			if(event.target.name == 'password')
			{
				setPassword(event.target.value)
			} else if (event.target.name == 'confirmpassword')
			{
				setConfirmPassword(event.target.value)
			}
		}

	const handleSubmit = (event) => {
			props.onResetPassword(identifier, password,confirmpassword);
			setPassword(null);
		}

	const welcomeBanner = {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center',
	};


	const bannerGrid = {
		paddingTop: '10px',
		alignItems: 'flex-start'
  	};

	return (
			<Layout>
				<Grid style={bannerGrid} item xs={12}>
					<Typography style={welcomeBanner} variant="h5">
					Reset Password
					</Typography>
				</Grid>
				<Grid container style={{paddingTop:'40px'}}>
					<Grid item xs={12}>
					<ValidatorForm onSubmit={handleSubmit} >
					<Typography variant="body1" color="error">{errMsg}</Typography>
					<Typography variant="body1" color="primary">{successMsg}</Typography>
					<Grid item xs={12}>
					 <TextValidator
					 style={inputStyle}
					  type = "password"
					 variant="outlined"
			         label="New Password"
			         onChange={ handleChange }
			         name="password"
			          value = {password}
			         validators = {['required']} 
			         errorMessages={['this field is required']}
			        />
			         <TextValidator
			           style={inputStyle}
					 variant="outlined"
			          type = "password"
			          label="Confirm Password"
			         onChange={ handleChange }
			         name="confirmpassword"
			          value = {confirmpassword}
			         validators = {['required']} 
			         errorMessages={['this field is required']}
			        />
			        <input type="hidden" name="link" value={process.env.REACT_APP_URL + '/' + 'reset-password'} />
			       </Grid>
					<Grid item xs={12}>
		       		 <Button className = {classes.btnOval} type="submit" disabled = {submitted}  variant="contained" color="secondary" disableElevation>
		       		 Reset Link
			        </Button>
			        </Grid>
			         </ValidatorForm>
			         </Grid>
		        </Grid>
	        </Layout>
		);
}

const mapStateToProps = state => {
	return {
		error: state.user_reducer.error,
		success: state.user_reducer.success,
		userId : state.auth_reducer.userId
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		onResetPassword : (identifier,password,confirmpassword) => dispatch(actions.onResetPassword(identifier,password,confirmpassword))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ResetPassword);