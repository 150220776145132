import React from 'react';
import Layout from '../../hoc/Layout/Layout';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import FriendBoxImg from '../../assets/images/friends_graph.png';
import ActivityBoxImg from '../../assets/images/activities_graph.png';
import LakeCompareButtonImg from '../../assets/images/lake_compare_button.png';
import { makeStyles } from '@material-ui/core/styles';
import ReactTooltip from "react-tooltip";


const useStyles = makeStyles((theme) => ({
  img : {
  	margin : '40px',
  	marginBottom : '5px'
  },
  rowPadding : {
  	paddingTop : '10%',
  },
  linkStyle : {
  	textDecoration : 'none'
  },
  welcomeBanner: {
	backgroundColor: '#454545',
	color: `white`,
	padding: '20px 50px 20px 50px',
	  textAlign: 'center'
  },
  bannerGrid: {
	paddingTop: '10px'
  },
  popoverContainer: {
    padding: '10px'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

const SampleDashboard = () => {

	const classes = useStyles();
	const [popoverOpen, setPopoverOpen] = React.useState(false);

	const handleSetPopoverOpen = (activity) => {
		console.log('open');
		setPopoverOpen(activity);
	};
	
	const handleSetPopoverClose = () => {
		console.log('close');
		//setPopoverOpen(null);
	};

	return (
		<Layout>
			<Grid className={classes.bannerGrid} item xs={12}>
				<Typography className={classes.welcomeBanner} variant="h4">
				My FLM Dashboard Example
				</Typography>
			</Grid>

			<Typography variant="h6" style={{marginBottom : '20px',paddingTop:'15px'}}>Welcome, Ali</Typography>
			
			<Grid container justify="center" item xs={12}>

				<Grid item xs={3}>
					<img data-tip data-for="friendTip" src={FriendBoxImg}/>
					<ReactTooltip id="friendTip" place="top" effect="solid">
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="body1">
									With each friend you refer (and any new friends that those friends
									add) you will record a 1 Friends touchpoint.
								</Typography>
							</Grid>
						</Grid>
					</ReactTooltip>
				</Grid>

				<Grid item xs={3}>
					<img data-tip data-for="activityTip" src={ActivityBoxImg}/>
					<ReactTooltip id="activityTip" place="top" effect="solid">
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="body1">
									Each time you attend and Event or Volunteer, you will record
									1 a touchpoint.
								</Typography>
							</Grid>
						</Grid>
					</ReactTooltip>
				</Grid>

				{/*
				<Grid item xs={3}>
					<img src={FlSmartBoxImg} />
					<Typography variant="body1">Fl Smarts</Typography>
				</Grid>
				<Grid item xs={3}>
					<img src={CommerceBoxImg} />
					<Typography variant="body1">Commerce</Typography>
				</Grid>
				*/}
			</Grid>

			<Grid container justify="center">
	
				<Typography variant="h6" style={{marginTop : '20px', color : '#A20B0B'}}>
					As your museum involvement increases -  see how you inspire others, 
					learn about and contribute to the great Finger Lakes Museum Ecosystem.
				</Typography>
				<Grid style={{paddingTop:'20px'}} item xs={12}>
					<img data-tip data-for="compareButtonTip" src={LakeCompareButtonImg}/>
					<ReactTooltip id="compareButtonTip" place="top" effect="solid">
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="body1">
									See how your lakes museum participation compares to the other lakes
									in the Finger Lakes Museum region.
								</Typography>
							</Grid>
						</Grid>
					</ReactTooltip>
				</Grid>
	        </Grid>

		</Layout>
		);

}

export default SampleDashboard;