import React from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    orangeIconButton: {
        backgroundColor: "#DC9A27",
        borderRadius: "10px",
        margin: '10px',
        '&:hover': {
            backgroundColor: "#28DBE8"
        }
      },
      popoverContainer: {
        padding: '10px'
      },
      paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      inputContainer: {
          padding: '10px 0px 10px 0px'
      },
      titleContainer: {
          paddingBottom: '20px'
      },
      submitButtonContainer: {
          textAlign: 'right'
      },
      modalTitle: {
        textAlign: 'center'
      },
      nameField: {
          width: '100%'
      }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    }
}

const DeleteUserModal = (props) => {

	const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const handleDeleteUser = () => {
        props.submitAction(props.data.id, function(result, error_message) {
            if(result) {
                props.handleDeleteModalClose();
            } else {
                alert(error_message);
            }
        });
    }

	return (
        <Modal
            id={"deleteModal"}
            open={props.open}
            onClose={props.handleDeleteModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">

            <div style={modalStyle} className={classes.paper}>
                <Grid container className={classes.titleContainer}>
                    <Grid item xs={12}>
                        <Typography className={classes.modalTitle} variant="h4">
                            Delete User
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.titleContainer}>
                    <Grid item xs={12}>
                        <Typography className={classes.modalTitle} variant="body1">
                            Are you sure you want to delete {props.data.userName}? The user
                            will no longer have login access but will remain in the database.
                        </Typography>
                    </Grid>
                </Grid>

                <ValidatorForm method="Post" onSubmit={handleDeleteUser}>
                    <Grid container className={classes.submitButtonContainer}>
                        <Grid item xs={12}>
                            <Button onClick={props.handleDeleteModalClose} className={classes.orangeIconButton} variant="contained">Cancel</Button>
                            <Button type="submit" className={classes.orangeIconButton} variant="contained">Confirm</Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </div>
        </Modal>
	);
}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token
 	}
}

export default connect(mapStateToProps)(DeleteUserModal);
