import React, {useEffect} from 'react';
import Layout from '../../hoc/Layout/Layout';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import  {connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import * as actions from '../../store/actions/index';
import Grid from '@material-ui/core/Grid';
import ActivityGraph from './ActivityGraph';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
	welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
  	},
 	root : {
  		padding : '20px',
  	},
  	img : {
  		margin : '40px',
  		marginBottom : '5px'
  	},
  	rowPadding : {
  		paddingTop : '10%'
  	},
	orangeButton: {
		backgroundColor: "#DC9A27",
		borderRadius: "20px",
		'&:hover': {
			backgroundColor: "#28DBE8"
		}
	}
}));


const Dashboard = (props) => {

	const classes = useStyles();

	useEffect(() => {
		if(props.userId == null){
	  		props.userLoad(props.userId);
	  	}
	  },[]);

	useEffect(() => {
		props.lakeLoad(props.lakeId);
	  	},[props.lakeId]);

	if(!props.token) {
		return <Redirect to="/auth" />;
	}

	let activity = (
			<React.Fragment>
				<Grid container justify="center" item xs={12}>
					<Grid item xs={3}>
						<ActivityGraph name="Friends" value="0" color="#BA10CC"  />
						<Typography variant="h5">0 </Typography>
					</Grid>
					<Grid item xs={3}>
						<ActivityGraph name="Activities" value="0" color="#3014C4"/>
						<Typography variant="h5">0 </Typography>
					</Grid>
					{/*
					<Grid item xs={3}>
						<ActivityGraph name="Fl Smarts" value="0" color = "#00A7E1"/>
					</Grid>
					<Grid item xs={3}>
						<ActivityGraph name="Commerce" value="0" color="#84F0A1"   />
					</Grid>
					*/}
				</Grid>
			</React.Fragment>
		);

	if(props.activity != null)
	{
		activity = (
			<React.Fragment>
				<Grid container justify="center" item xs={12}>
					<Grid item xs={3}>
						<ActivityGraph name="Friends" value={ props.activity["1"] ? ((props.activity["1"] / 100)*100)  : 0 } color="#BA10CC"  />
						<Typography variant="h5">{props.activity["1"] ? props.activity["1"] : 0}</Typography>
					</Grid>
					<Grid item xs={3}>
						<ActivityGraph name="Activities" value={ props.activity["5"] ? ((props.activity["5"] / 100)*100) : 0  } color="#3014C4"/>
						<Typography variant="h5">{props.activity["5"] ? props.activity["5"] : 0}</Typography>
					</Grid>
					{/*
					<Grid item xs={3}>
						<ActivityGraph name="Fl Smarts" value="0" color = "#00A7E1"/>
					</Grid>
					<Grid item xs={3}>
						<ActivityGraph name="Commerce" value={ props.activity["4"] ? ((props.activity["4"] / 100)*100) : 0  } color="#84F0A1"   />
					</Grid>
					*/}
				</Grid>
			</React.Fragment>
		);
	}

	return (
		<Layout isAuth={props.token}>
		 <Grid className={classes.bannerGrid} item xs={12}>
			<Typography className={classes.welcomeBanner} variant="h5">
				My Dashboard
			</Typography>
		
		</Grid>
		 <Grid container>
			{ activity }
		</Grid>

		<Grid style={{paddingTop:'50px'}} justify="center" container>
			<Link style={{ textDecoration: 'none' }} to="/lake-participation"> 
				<Button size="large" className={classes.orangeButton} variant="contained">See how your lake compares</Button>
			</Link>
		</Grid>

		<Grid style={{paddingTop:'50px'}} justify="center" container>
			<Link style={{ textDecoration: 'none' }} to="/trail-progress"> 
				<Button size="large" className={classes.orangeButton} variant="contained">View your trail progress</Button>
			</Link>
		</Grid>
		</Layout>
	);
}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		lakeId : state.user_reducer.lakeId,
		lake : state.user_reducer.lake,
		token : state.auth_reducer.token,
		activity : state.user_reducer.activity,
		user : state.user_reducer.user
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId)),
		lakeLoad : (lakeId) => dispatch(actions.getLake(lakeId)),
		onLogOut : () => dispatch(actions.authLogout()),
		loadActivity : (userId) => dispatch(actions.loadActivity(userId))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);