import React from 'react';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../components/UI/Button/CustomButton';
import Layout from '../hoc/Layout/Layout';
import  {connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
	},
    orangeButton: {
		backgroundColor: "#DC9A27",
		borderRadius: "20px",
		'&:hover': {
			backgroundColor: "#28DBE8"
		},
        width: '300px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        padding: '8px 22px',
		textDecoration: 'none',
		color: 'black'
	},
	buttonContainer: {
		paddingTop: '30px'
	}
}));

const Volunteer = (props) => {
	const classes = useStyles();
	const btnBlue = '#28DBE8';

	return (
			<Layout isAuth={props.token}>
				<Grid className={classes.bannerGrid} item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h6">
					Volunteering? Get the event's QR code from the event coordinator 
                    and use the Scan button below to scan the code and record a touchpoint!
					</Typography>
				</Grid>
				
				<CustomButton className="dash-custom-button" redirectLink="/event-scanner" btnColor={btnBlue}>Scan QR Code</CustomButton>
		<Grid className={classes.bannerGrid} container>
				<Grid className={classes.buttonContainer} item xs={12}>
					<a href="https://www.fingerlakesmuseum.org/programs-events/open-house-volunteer-site-clean-up" target="_blank" className={classes.orangeButton} variant="contained">Link to Volunteer Events!</a>
				</Grid>
			</Grid>
			</Layout>
		);

}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token
 	}
}


export default connect(mapStateToProps)(Volunteer);