import * as actionTypes from './actionTypes';
import axios from 'axios';

export const fetchLakes = (lakes) => {
	return {
		type : actionTypes.GET_LAKES,
		lakes : lakes
	}
}

export const setLakesParticipation = (participations) => {
	return {
		type : actionTypes.LOAD_PARTICIPATIONS,
		participations : participations
	}
}

export const findLocalLakes = () => {
	return dispatch => {
		const lakes =  JSON.parse(localStorage.getItem('lakes'));
		if(lakes) {
			dispatch(fetchLakes(lakes))
		}
	}
}

export const loadParticipations = () => {
	return dispatch => {
		axios.get(process.env.REACT_APP_API_URL + '/lakes/participation')
			.then(response=> {
				if(response.data.success==true)
				{
				dispatch(setLakesParticipation(response.data.LakesParticipation))	 
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
}

export const getLakes = () => {
	return dispatch => {
		// let lakes = localStorage.getItem('lakes');
		// if(lakes) {
		// 	lakes = JSON.parse(localStorage.getItem('lakes'));
		// 	dispatch(fetchLakes(lakes));
		// } else {
		axios.get(process.env.REACT_APP_API_URL + '/lakes')
			.then(response=> {
				if(response.data.success==true)
				{
				//localStorage.setItem('lakes', JSON.stringify(response.data.lakes));
				dispatch(fetchLakes(response.data.lakes))	 
				}
			})
			.catch(err => {
				console.log(err);
			});
		// }
	}
}

