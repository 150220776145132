import * as actionTypes from './actionTypes';
import axios from 'axios';
import { isExpired, decodeToken } from "react-jwt";

export const authStart = () => {
	return {
		type : actionTypes.AUTH_START
	}
}

export const authSuccess = (token, userId, user, idLake) => {
	return {
		type : actionTypes.AUTH_SUCCESS,
		idToken : token,
		userId : userId,
		user: user,
		idLake: idLake
	}
}


export const authFail = (error) => {
	return {
		type : actionTypes.AUTH_FAIL,
		error : error
	}
}

export const checkAuthTimeout = (expirationTime)=> {
	return dispatch => {
		setTimeout(()=>{
			dispatch(logout());
			//expiration time to be in seconds
		}, expirationTime);

	}
}

export const auth = (email, password) => {
	return dispatch => {
		dispatch(authStart());
		axios.get(process.env.REACT_APP_API_URL + '/user/login?Email=' + email + '&Password=' + password)
			.then(response=> {
				if(response.data.success==true)
				{
				const myDecodedToken = decodeToken(response.data.token);
				console.log('myDecodedToken', myDecodedToken);
				//const expirationDate = new Date(new Date().getTime() + response.data.exp * 1000);
				localStorage.setItem('userId', response.data.id);
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('user', JSON.stringify(response.data.user));
				localStorage.setItem('idLake', response.data.idLake);
				//localStorage.setItem('expirationDate', expirationDate);

				dispatch(authSuccess( response.data.token, response.data.id, response.data.user, response.data.idLake));
				//dispatch(checkAuthTimeout());

				} else {
					dispatch(authFail(response.data.message));
				}
			})
			.catch(err => {
				dispatch(authFail(err.message + '. Try again!'));
			});
	}
}

export const authCheckState = () => {
	return dispatch => {
		const id = localStorage.getItem('userId');
		const token = localStorage.getItem('token');
		const user = localStorage.getItem('user');
		const idLake = localStorage.getItem('idLake');
		if(token) {
			dispatch(authSuccess(token,id, user, idLake))
		}
	}
}

export const logout = () => {
	return {
		type : actionTypes.AUTH_LOGOUT
	}
}

export const authLogout = () => {
	return dispatch => {
		localStorage.removeItem('userId')
		localStorage.removeItem('token')
		localStorage.removeItem('user')
		localStorage.removeItem('idLake')
		dispatch(logout())
	}
}
