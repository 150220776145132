import React , { useEffect }  from 'react';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';
import {Link, useParams, Redirect} from "react-router-dom";
import {Grid, Button, Typography} from '@material-ui/core';
import Layout from '../../hoc/Layout/Layout';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	welcomeBanner: {
		  backgroundColor: '#454545',
		  color: `white`,
		padding: '20px 50px 20px 50px',
		textAlign: 'center'
	},
	bannerGrid: {
		paddingTop: '10px'
	},
	orangeButton: {
		backgroundColor: "#DC9A27",
		borderRadius: "20px",
		margin: '10px',
		'&:hover': {
			backgroundColor: "#28DBE8"
		}
	},
	message: {
		paddingTop: '20px',
		paddingBottom: '20px'
	}
});

const Award = (props) => {

	 let { awardType } = useParams();
	 let { idObject } = useParams();
	 const classes = useStyles();

	 useEffect(() => {
	  		props.onLoad(idObject,awardType,props.userId);
	  },[]);
	  console.log('award', props);
	/*
	  if(!props.token) {
		return <Redirect to="/" />;
	}
*/
	
	 let awardStatus = 'loading';

	 if(props.award_status != '')
	 {
	 	awardStatus = props.award_status;
	 }

	return (
		<Layout footer={true} showLake={false} isAuth={props.token}>
			<Grid className={classes.bannerGrid} container>
				<Grid item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h5">
						Touchpoint Result
					</Typography>
				</Grid>
			</Grid>

			<Grid className={classes.bannerGrid} container>
				<Grid className={classes.buttonContainer} item xs={12}>
					<Typography className={classes.message} variant="h4">
						{awardStatus}
					</Typography>
				</Grid>
			</Grid>

			<Grid className={classes.bannerGrid} justify="center" container>
				<Link style={{ textDecoration: 'none' }} to="/dashboard"> 
					<Button size="large" className={classes.orangeButton} variant="contained">Visit Your Dashboard</Button>
				</Link>
			</Grid>

		</Layout>
		);
}

const mapStateToProps = state => {
	return {
		trails : state.trail_reducer.trails,
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token,
		award_status : state.trail_reducer.award_status,
		user : state.user_reducer.user
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		onLoad : (idObject, awardType , user_id) => dispatch(actions.giveUserAward(idObject,awardType,user_id))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Award);