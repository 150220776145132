import React, { useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Layout from '../../hoc/Layout/Layout';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';
import LakeParticipation from './LakeParticipation';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
     flexGrow: 1
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
   // backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block', 
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%'
  },
  footer : {
  	paddingLeft: '44%',
  	background : 'none',
  	paddingTop : '20px'
  },
  slideContainer: {
     ['@media (min-width:1250px)']: {padding: '0 60px'},
      ['@media (max-width:600px)'] : {padding: '0 70px'},
    marginTop: '20px'
  },
  welcomeBanner: {
    backgroundColor: '#454545',
    color: `white`,
    padding: '20px 50px 20px 50px',
    textAlign: 'center'
  },
  bannerGrid: {
    paddingTop: '10px'
    },
}));

const LakeParticipationList = (props) => {

   useEffect(() => {
     props.onLoad();
     props.loadParticipations();
  },[]);

  
   let allLakes = null;
   let allLakesParticipation = <p>Loading lakes...</p>;

    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    let maxSteps = 0;

    const handleNext = () => {
     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

    if(props.lakes != null && props.participations !=null)
  	 { 
  		allLakes = (
  			props.lakes.map(lake=>{
  				return { 
  					label : lake.LakeName, 
  					imgPath : process.env.REACT_APP_URL + '/images/lakes/' + lake.Img,
            svgCode : lake.svgCode,
            participation : props.participations.find(participation=>participation.id == lake.idLake).participation
  				}
  			})
  		);

      maxSteps = allLakes.length;


      allLakesParticipation = (<React.Fragment><SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        className = {classes.slideContainer}
      >
        {allLakes.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
                <LakeParticipation name={step.label} svgCode={step.svgCode} participation={step.participation} />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
       className={classes.footer}
      /></React.Fragment>);
  	}

	return (
		<Layout footer={true} showLake={false}>
		
		<div className={classes.root}>
    <Grid className={classes.bannerGrid} item xs={12}>
      <Typography className={classes.welcomeBanner} variant="h5">
      Lake Participation
      </Typography>
    </Grid>
            {allLakesParticipation}
    </div>
    </Layout>
		);
}

const mapStateToProps = state => {
	return {
		lakeId : state.user_reducer.lakeId,
		token : state.auth_reducer.token,
		lakes : state.lake_reducer.lakes,
    participations : state. lake_reducer.participations
 	}
}	

const mapDispatchToProps = dispatch => {
	return {
		onLoad : () => dispatch(actions.getLakes()),
    loadParticipations : () => dispatch(actions.loadParticipations())
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(LakeParticipationList);