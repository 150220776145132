import React, { useEffect }  from 'react';
import Typography from '@material-ui/core/Typography';
import MainNavigation from '../../components/UI/Navigation/MainNavigation';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Button } from '@material-ui/core';
import  {connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import * as actions from '../../store/actions/index';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  	root : {
		textAlign : 'center',
	},
	img : {
		margin : '40px',
		marginBottom : '5px',
		textAlign: 'left'
	},
	linkStyle : {
		textDecoration : 'none',
		textAlign: 'left'
	},
	footerText: {
		color: 'white',
		textAlign: 'center'
	},
	footerContainer: {
		backgroundColor: '#454545',
		padding: '15px 0px 15px 0px',
		textAlign: 'center'
	},
	navBar: {
		backgroundColor: 'white'
	},
	flexWrapper: {
		display: 'flex',
		minHeight: '100vh',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	footerLink:{
		color:"white"
	}
}));

const Layout = (props) => {

	const classes = useStyles();
	let history = useHistory();
	let theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		if(props.idUser && !props.user){
			props.userLoad(props.idUser);
		}
	});

	let main_body_style = {
		minHeight: '300px'
	};
	if (!isMobile) {
		main_body_style.minHeight = '600px';
	}

	return <React.Fragment>
			<Grid container fixed={'true'} className = {classes.root}>
					<Grid item xs={'auto'} sm={4} md={2}/>
					<Grid item xs={12} sm={4} md={8}>
						<div className={""}>
							<header className={classes.navBar}>
								<MainNavigation> </MainNavigation>
							</header>
							<main style={main_body_style} id="main-content">
								{props.children}

							</main>



							<footer>
								<Paper className={classes.footerContainer} elevation={1}>
									<Typography className={classes.footerText} variant="h5" component="h3">
										<a href="https://www.fingerlakesmuseum.org/" target="_blank" className={classes.footerLink}> Fantastic Friends of the Fingerlakes Museum</a> &#169;2021 All right reserved
									</Typography>
								</Paper>
							</footer>
						</div>
					 </Grid>
					 <Grid item xs={'auto'} sm={4} md={2}/>
			</Grid>

	</React.Fragment>

}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token,
		user: state.user_reducer.user,
		lake : state.user_reducer.lake,
		activity : state.user_reducer.activity
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId)),
		lakeLoad : (lakeId) => dispatch(actions.getLake(lakeId))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Layout);
