import React, { useEffect }  from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import lakes from '../assets/images/Finger_Lakes_Graphic.png';
import Link from '@material-ui/core/Link';
import { Link as RouterLink , useParams} from "react-router-dom";
import Layout from '../hoc/Layout/Layout';
import { Grid } from '@material-ui/core';
import * as actions from '../store/actions/index';
import  {connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const Home = (props) => {

	const useStyles = makeStyles({
		welcomeBanner: {
			  backgroundColor: '#454545',
			  color: `white`,
			padding: '20px 50px 20px 50px',
			textAlign: 'center'
		},
		bannerGrid: {
			paddingTop: '10px'
		},
		lakesGraphic: {
			width: useMediaQuery(useTheme().breakpoints.down('sm')) ? '80%' : '50%',
			backgroundColor: 'white',
			marginTop: '15px'
		},
		orangeButton: {
			backgroundColor: "#DC9A27",
			borderRadius: "20px",
			margin: '10px',
			'&:hover': {
				backgroundColor: "#28DBE8"
			}
		},
		homeContent: {
			backgroundColor: 'transparent',
			backgroundImage: 'linear-gradient(0deg, transparent 24%, rgba(63, 84, 191, .3) 25%, rgba(63, 84, 191, .3) 26%, transparent 27%, transparent 74%, rgba(63, 84, 191, .3) 75%, rgba(63, 84, 191, .3) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(63, 84, 191, .3) 25%, rgba(63, 84, 191, .3) 26%, transparent 27%, transparent 74%, rgba(63, 84, 191, .3) 75%, rgba(63, 84, 191, .3) 76%, transparent 77%, transparent)',
			height: '100%',
			backgroundSize: '50px 50px'		
		},
		buttonBox: {
			padding: '30px 0px 30px 0px',
			backgroundColor: 'white'
		},
		exampleButtonBox: {
			marginTop: '50px'
		}
	});

	const btnBlue = '#28DBE8';
	const btnOrange = '#DC9A27';
	let { referId } = useParams();

	 useEffect(() => {
	  	if(referId != null){
	  		props.setReferId(referId);
	  	}
	  });	 	

	const classes = useStyles();

	const theme = {
		spacing: 8
	}

	return (
		<Layout>
			<Grid className={classes.bannerGrid} item xs={12}>
				<Typography className={classes.welcomeBanner} variant="h6">
				Welcome to Fantastic Friends of 
				the Finger Lakes Museum. You are joining a community committed to the
				Finger Lakes region through inspiration, education, and entertainment.
				Use this app to add friends and activities to inspire others!
				</Typography>
	        </Grid>

	        <Grid className={""} item xs={12}>
				<Box className={classes.buttonBox}>
					<RouterLink style={{ textDecoration: 'none', marginRight: '20px' }} to="/select-lake"> 
						<Button size="large" variant="contained" className={classes.orangeButton}>New Friend? Pick a Lake</Button>
					</RouterLink>
					<RouterLink style={{ textDecoration: 'none' }} to="/auth"> 
						<Button size="large" className={classes.orangeButton} variant="contained">Already a Friend</Button>
					</RouterLink>
				</Box>
				<Box>
					<Link href="#">
						<img className={classes.lakesGraphic} src = { lakes } alt = "all_finger_lakes" />
					</Link>
				</Box>
				<Box className={classes.exampleButtonBox}>
					<RouterLink style={{ textDecoration: 'none' }} to="/sample-dashboard"> 
						<Button size="large" className={classes.orangeButton} variant="contained">See a Dashboard Example</Button>
					</RouterLink>
				</Box>
	        </Grid>
	        
	</Layout>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		setReferId : (referId) => dispatch(actions.setReferId(referId))
	}
}

export default connect(null,mapDispatchToProps)(Home);