import * as actionTypes from '../actions/actionTypes';

const initialState = {
	trails : null,
	award_status : ''
}

const reducer = (state = initialState, action) => {

	switch(action.type) {
	  case actionTypes.SET_TRAILS:
	    return {
	    	...state,
	    	trails : action.trails
		}
	  case actionTypes.SET_AWARD_STATUS:
	  	return {
	  		...state,
	  		award_status : action.award_status
	  	}
	}
	
	return state;
}


export default reducer;