import React, { useEffect }  from 'react';
import Typography from '@material-ui/core/Typography';
import TrailList from '../Trail/TrailList';
import Layout from '../../hoc/Layout/Layout';
import  {connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal } from '@material-ui/core';
import * as actions from '../../store/actions/index';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
	modalTitle: {
        textAlign: 'center'
    },
    welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
  	},
    trailListContainer: {
        paddingTop: '20px'
    },
    orangeButton: {
        backgroundColor: "#DC9A27",
        borderRadius: "10px",
        margin: '10px',
        '&:hover': {
            backgroundColor: "#28DBE8"
        }
    },
    newTrailButtonWrapper: {
        textAlign: 'right',
        paddingTop: '20px'
    },
    titleWrapper: {
        textAlign: 'left',
        paddingTop: '20px'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    inputContainer: {
        padding: '10px 0px 10px 0px'
    },
    titleContainer: {
        paddingBottom: '20px'
    },
    submitButtonContainer: {
        textAlign: 'right'
    }
}));

const AdminTrails = (props) => {
	const classes = useStyles();
	const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [trailName, setTrailName] = React.useState(false);

    useEffect(() => {
        if(props.trails == null)
        {
            props.loadTrails();
        }
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTrailNameChange = (event) => {
        setTrailName(event.target.value);
    };

    const handleModalClose = () => {
        setTrailName("");
        setOpen(false);
    }

    const handleSubmitNewTrail = () => {
        props.submitNewTrail({
            TrailName: trailName
        }, handleModalClose());
    }

    const modalBody = (
        <div style={modalStyle} className={classes.paper}>
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.modalTitle} variant="h4">
                        Create New Trail
                    </Typography>
                </Grid>
            </Grid>
            <ValidatorForm method="Post" onSubmit={handleSubmitNewTrail}>
                <Grid container className={classes.inputContainer}>
                    <Grid item xs={12} sm={6}>
                        <TextValidator
                            id="trailName"
                            label="Trail Name"
                            value={trailName ? trailName : ""}
                            onChange={handleTrailNameChange}
                            helperText="Enter the name of the trail"
                            validators = {['required']}
                            errorMessages={['This field is required']}>
                        </TextValidator>
                    </Grid>
                </Grid>
                <Grid container className={classes.submitButtonContainer}>
                    <Grid item xs={12}>
                        <Button onClick={handleModalClose} className={classes.orangeButton} variant="contained">Cancel</Button>
                        <Button type="submit" className={classes.orangeButton} variant="contained">Save</Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </div>
    );

	return (
			<Layout isAuth={props.token}>
				<Grid className={classes.bannerGrid} item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h4">
					View and Create Trails
					</Typography>
				</Grid>

                <Grid container justify="flex-end">
                    <Grid className={classes.newTrailButtonWrapper} item xs={12} sm={6}>
                        <Typography className={classes.titleWrapper} variant="h5">
                            Trails
                        </Typography>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description">
                            {modalBody}
                        </Modal>
                    </Grid>
                    <Grid className={classes.newTrailButtonWrapper} item xs={12} sm={6}>
                        <Button size="large" onClick={handleOpen} className={classes.orangeButton} variant="contained">Create New Trail</Button>
                    </Grid>
                </Grid>

                <Grid container className={classes.trailListContainer}>
                    <Grid item xs={12}>
				        <TrailList trails={props.trails}/>
                    </Grid>
                </Grid>
			</Layout>
		);

}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token,
        trails : state.trail_reducer.trails
    }
}

const mapDispatchToProps = dispatch => {
	return {
		submitNewTrail : (formData) => dispatch(actions.submitNewTrail(formData)),
        loadTrails: () => dispatch(actions.getTrails())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTrails);
