import React, { useEffect }  from 'react';
import Typography from '@material-ui/core/Typography';
import EventList from '../Event/EventList';
import Layout from '../../hoc/Layout/Layout';
import  {connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal, MenuItem } from '@material-ui/core';
import * as actions from '../../store/actions/index';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
	modalTitle: {
        textAlign: 'center'
    },
    welcomeBanner: {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center'
  	},
  	bannerGrid: {
		paddingTop: '10px'
  	},
    eventListContainer: {
        paddingTop: '20px'
    },
    orangeButton: {
        backgroundColor: "#DC9A27",
        borderRadius: "10px",
        margin: '10px',
        '&:hover': {
            backgroundColor: "#28DBE8"
        }
    },
    newEventButtonWrapper: {
        textAlign: 'right',
        paddingTop: '20px'
    },
    titleWrapper: {
        textAlign: 'left',
        paddingTop: '20px'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    inputContainer: {
        padding: '10px 0px 10px 0px'
    },
    titleContainer: {
        paddingBottom: '20px'
    },
    submitButtonContainer: {
        textAlign: 'right'
    }
}));

const AdminEvents = (props) => {
	const classes = useStyles();
	const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [isVolunteer, setVolunteer] = React.useState(false);
    const [eventName, setEventName] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setVolunteer(event.target.value);
    };

    const handleEventNameChange = (event) => {
        setEventName(event.target.value);
    };

    const handleModalClose = () => {
        setEventName("");
        setVolunteer(0);
        setOpen(false);
    }

    const handleSubmitNewEvent = () => {
        props.submitNewEvent({
            IsVolunteer: isVolunteer,
            EventName: eventName
        }, handleModalClose());
    }

    const modalBody = (
        <div style={modalStyle} className={classes.paper}>
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.modalTitle} variant="h4">
                        Create New Event
                    </Typography>
                </Grid>
            </Grid>
            <ValidatorForm method="Post" onSubmit={handleSubmitNewEvent}>
                <Grid container className={classes.inputContainer}>
                    <Grid item xs={12} sm={6}>
                        <TextValidator
                            id="eventName"
                            label="Event Name"
                            value={eventName ? eventName : ""}
                            onChange={handleEventNameChange}
                            helperText="Enter the name of the event"
                            validators = {['required']}
                            errorMessages={['This field is required']}>
                        </TextValidator>
                    </Grid>
                </Grid>
                <Grid container className={classes.inputContainer}>
                    <Grid item xs={12} sm={6}>
                        <TextValidator
                            id="isVolunteer"
                            select
                            label="Is Volunteer"
                            value={isVolunteer}
                            onChange={handleChange}
                            helperText="Is this a volunteer event?">
                            <MenuItem key={"Yes"} value={1}>
                                Yes
                            </MenuItem>
                            <MenuItem key={"No"} value={0}>
                                No
                            </MenuItem>
                        </TextValidator>
                    </Grid>
                </Grid>
                <Grid container className={classes.submitButtonContainer}>
                    <Grid item xs={12}>
                        <Button onClick={handleModalClose} className={classes.orangeButton} variant="contained">Cancel</Button>
                        <Button type="submit" className={classes.orangeButton} variant="contained">Save</Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </div>
    );

	return (
			<Layout isAuth={props.token}>
				<Grid className={classes.bannerGrid} item xs={12}>
					<Typography className={classes.welcomeBanner} variant="h4">
					View and Create Events
					</Typography>
				</Grid>

                <Grid container justify="flex-end">
                    <Grid className={classes.newEventButtonWrapper} item xs={12} sm={6}>
                        <Typography className={classes.titleWrapper} variant="h5">
                            Events
                        </Typography>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description">
                            {modalBody}
                        </Modal>
                    </Grid>
                    <Grid className={classes.newEventButtonWrapper} item xs={12} sm={6}>
                        <Button size="large" onClick={handleOpen} className={classes.orangeButton} variant="contained">Create New Event</Button>
                    </Grid>
                </Grid>

                <Grid container className={classes.eventListContainer}>
                    <Grid item xs={12}>
				        <EventList events={props.events}/>
                    </Grid>
                </Grid>
			</Layout>
		);

}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token,
        events : state.event_reducer.events
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		submitNewEvent : (formData) => dispatch(actions.submitNewEvent(formData)),
        loadEvents: () => dispatch(actions.getEvents())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEvents);
