export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const GET_LAKES = 'GET_LAKES';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const SET_USER_LAKE = 'SET_USER_LAKE';
export const GET_LAKE = 'GET_LAKE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REMOVE_LAKE = 'REMOVE_LAKE';
export const SET_REFER_ID = 'SET_REFER_ID';
export const LOAD_PARTICIPATIONS = 'LOAD_PARTICIPATIONS';
export const SET_TRAILS = 'SET_TRAILS';
export const SET_AWARD_STATUS = 'SET_AWARD_STATUS';
export const SET_USER_ACTIVITY = 'SET_USER_ACTIVITY';
export const SET_EVENTS = 'SET_EVENTS';
export const SAVE_NEW_EVENT = 'SAVE_NEW_EVENT';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SET_USERS = 'SET_USERS';
