import { createMuiTheme} from '@material-ui/core/styles';

const blue = '#28DBE8';
const red = '#DC9A27';


export default createMuiTheme({

	palette : {
		common : {
			blue : `${blue}`,
			red : `${red}`
		},
		primary : {
			main : `${blue}`
		},
		secondary : {
			main : `${red}`	
		}
	},
	shape : {
		oval : {
		      width: '200px',
		      height: '100px',
		      borderRadius: '100px / 50px',
		      margin : '25px 5px 20px 5px',
		      textTransform : 'none',
		      border : '1px solid #707070'
		  }
	}
});