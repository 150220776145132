import React , { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Layout from '../../hoc/Layout/Layout';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';
import { Redirect, Link as RouterLink} from "react-router-dom";


class Auth extends Component {

	state = {
		formData : {
			email : '',
			password : ''
		},
		submitted : false
	}

	handleChange = (event) => {
		const {formData} = this.state;
		formData[event.target.name] = event.target.value;
		this.setState({
		...this.state.submitted,
        formData : formData
      })
	}

	handleSubmit = (event) => {
			this.props.onAuth(this.state.formData.email, this.state.formData.password)
	}

	render() {

	const inputStyle = {
 	width : '60%',
 	marginBottom: '20px'
 	}

 	const btnOval = {
		      width : '200px',
		      height: '100px',
		      borderRadius: '100px / 50px',
		      margin : '25px 5px 20px 5px',
		      textTransform : 'none',
		      border : '1px solid #707070'
		  }

	const welcomeBanner = {
		backgroundColor: '#454545',
		color: `white`,
		padding: '20px 50px 20px 50px',
	  	textAlign: 'center',
	};

	const orangeButton = {
		backgroundColor: "#DC9A27",
		borderRadius: "20px",
		minWidth: '250px',
		'&:hover': {
			backgroundColor: "#28DBE8"
		}
	}

	const bannerGrid = {
		paddingTop: '10px',
		alignItems: 'flex-start'
  	};

	const {formData, submitted} = this.state;

	let errMsg = null;

	if(this.props.error) {
		errMsg = (
				<p>{this.props.error}</p>
			);
	}

	if(this.props.userId) {
		return <Redirect to="/friend" />;
	}

	if(this.props.lakeId) {
		this.props.removeUserLake();
	}

		return (
			<Layout>
				<Grid style={bannerGrid} item xs={12}>
					<Typography style={welcomeBanner} variant="h5">
					Login
					</Typography>
				</Grid>
				<Grid container style={{paddingTop:'40px'}}>
					<Grid item xs={12}>
						<ValidatorForm ref="form" onSubmit={this.handleSubmit}>
							<Typography variant="body1" color="error">{errMsg}</Typography>
							<Grid item xs={12}>
								<TextValidator
								style={inputStyle}
								variant="outlined"
								label="Email"
								onChange={ this.handleChange }
								name="email"
								value = {formData.email}
								validators = {['required','isEmail']} 
								errorMessages={['this field is required','email is not valid']}
								/>
								<TextValidator
								style={inputStyle}
								variant="outlined"
								type = "password"
								label="Password"
								onChange={ this.handleChange }
								name="password"
								value = {formData.password}
								validators = {['required']} 
								errorMessages={['this field is required']}
								/>
								<Grid container style={{paddingTop:'10px'}}>
									<Grid item xs={12}>
										<RouterLink style={{ textDecoration: 'none' }} to="/forget-password">
											<a href="">Forgot Password?</a>
										</RouterLink>
									</Grid>
								</Grid>

								<Grid container style={{paddingTop:'10px'}}>
									<Grid item xs={12}>
										<RouterLink style={{ textDecoration: 'none' }} to="/select-lake">
											<a href="">New Friend? Pick a lake and sign up here!</a>
										</RouterLink>
									</Grid>
								</Grid>
							</Grid>
						
							<Grid style={{paddingTop:'50px',paddingBottom:'50px'}} justify="center" container item xs={12}> 
								<Button size="large" style={orangeButton} type="submit" disabled={submitted} variant="contained">Login</Button>
							</Grid>
						</ValidatorForm>
			         </Grid>
					 <Grid container>
						 <Grid item xs={12}></Grid>
					 </Grid>
		        </Grid>
	        </Layout>
			);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.auth_reducer.loading,
		error: state.auth_reducer.error,
		userId : state.auth_reducer.userId,	
		lakeId: state.user_reducer.lakeId
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		onAuth : (email, password) => dispatch(actions.auth(email,password)),
		removeUserLake : () =>  dispatch(actions.removeLake())
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Auth);