import React from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    orangeIconButton: {
        backgroundColor: "#DC9A27",
        borderRadius: "10px",
        margin: '10px',
        '&:hover': {
            backgroundColor: "#28DBE8"
        }
      },
      popoverContainer: {
        padding: '10px'
      },
      paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      inputContainer: {
          padding: '10px 0px 10px 0px'
      },
      titleContainer: {
          paddingBottom: '20px'
      },
      submitButtonContainer: {
          textAlign: 'right'
      },
      modalTitle: {
        textAlign: 'center'
      },
      nameField: {
          width: '100%'
      }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    }
}

function getModalId(props) {
    let id = "";
    if (props.type === "edit") {
        id = "editmodal_"+props.idTrail;
    } else if (props.type === "delete") {
        id = "deletemodal_"+props.idTrail;
    }

    return id;
}

const EditTrailModal = (props) => {
    
	const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [trailName, setTrailName] = React.useState(() => {
        if (props.data && props.data.trailName) {
            return props.data.trailName;
        }
        return "";
    });

    const handleTrailNameChange = (event) => {
		setTrailName(event.target.value);
	};

    const handleDeleteTrail = (trail) => {
        props.deleteTrail({
          TrailName: trailName,
          idTrail: props.data.id
        }, props.handleDeleteModalClose());
    }
    
    const handleEditTrail = () => {
        props.editTrail({
            TrailName: trailName,
            idTrail: props.data.id
        }, props.handleEditModalClose());
    }

    const editTrailModalBody = () => (
        <div style={modalStyle} className={classes.paper}>
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.modalTitle} variant="h4">
                        Edit Trail
                    </Typography>
                </Grid>
            </Grid>
            <ValidatorForm method="Post" onSubmit={handleEditTrail}>
                <Grid container className={classes.inputContainer}>
                    <Grid item xs={12} sm={12}>
                        <TextValidator
                            className={classes.nameField}
                            id="trailName"
                            label="Trail Name"
                            value={trailName}
                            onChange={handleTrailNameChange}
                            helperText="Enter the name of the trail"
                            validators = {['required']} 
                            errorMessages={['This field is required']}>
                        </TextValidator>
                    </Grid>
                </Grid>
                <Grid container className={classes.submitButtonContainer}>
                    <Grid item xs={12}>
                        <Button onClick={props.handleEditModalClose} className={classes.orangeIconButton} variant="contained">Cancel</Button>
                        <Button type="submit" className={classes.orangeIconButton} variant="contained">Save</Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </div>
    );
  
    const deleteTrailModalBody = (data) => (
        <div style={modalStyle} className={classes.paper}>
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.modalTitle} variant="h4">
                        Delete Trail
                    </Typography>
                </Grid>
            </Grid>
    
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.modalTitle} variant="body1">
                        Are you sure you want to delete {data.trailName}? The trail
                        will no longer appear in the admin table and the QR code will no
                        longer be active.
                    </Typography>
                </Grid>
            </Grid>
    
            <ValidatorForm method="Post" onSubmit={handleDeleteTrail}>
                <Grid container className={classes.submitButtonContainer}>
                    <Grid item xs={12}>
                        <Button onClick={props.handleDeleteModalClose} className={classes.orangeIconButton} variant="contained">Cancel</Button>
                        <Button type="submit" className={classes.orangeIconButton} variant="contained">Confirm</Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </div>
    );

	return (
        <Modal
            id={getModalId(props.type)}
            open={props.open}
            onClose={props.handleDeleteModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            {props.type === "delete" ? deleteTrailModalBody(props.data) :
            editTrailModalBody(props.data)}
        </Modal>
	);
}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		token : state.auth_reducer.token
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		editTrail: (formData) => dispatch(actions.editTrail(formData)),
        deleteTrail: (formData) => dispatch(actions.deleteTrail(formData))
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(EditTrailModal);