import React , { useEffect } from 'react';
import Layout from '../../hoc/Layout/Layout';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import { Grid, Box } from '@material-ui/core';
import  {connect } from 'react-redux';
import { Redirect} from "react-router-dom";
import * as actions from '../../store/actions/index';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    welcomeBanner: {
      	backgroundColor: '#454545',
      	color: `white`,
		padding: '10px',
		textAlign: 'center',
		marginBotton: '20px'
	},
	bannerGrid: {
		paddingTop: '10px'
	},
    buttonBox: {
        padding: '50px',
        border: '1px solid #A0A0A0',
        borderRadius: '10px',
        textAlign: 'center'
    },
    linkHeader: {
        color: 'black',
        '&:hover': {
            color: 'blue'
        }
    },
    linkHeaderWrapper: {
        padding: '50px 0px 20px 0px'
    }
});

const Admin = (props) => {

    const classes = useStyles();

    useEffect(() => {
        if(props.trails == null)
        {
            props.trailsLoad();
        }
        if(props.events == null)
        {
            props.eventsLoad();
        }
        if(props.users == null)
        {
            props.usersLoad();
        }
    });

	if(!props.token) {
		return <Redirect to="/auth" />;
	}
	// console.log('props', props);
    return (
        <Layout footer={true} showLake={true}>
            <Grid className={classes.bannerGrid} item xs={12}>
                <Typography className={classes.welcomeBanner} variant="h5">
                    Welcome to the Fantastic Friends Admin Area
                </Typography>
            </Grid>
            <Grid container>
                <Grid className={classes.linkHeaderWrapper} container justify="center">
                    <Grid item xs={7}>
                        <Box className={classes.buttonBox}>
                            <Link to="/admin/events"> 
                                <Typography className={classes.linkHeader} variant="h4">
                                    View and Edit Events
                                </Typography>
                            </Link>
                            <Typography variant="h6">
                                Total Events: {props.events ? props.events.length : 0}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid className={classes.linkHeaderWrapper} container justify="center">
                    <Grid item xs={7}>
                        <Box className={classes.buttonBox}>
                            <Link to="/admin/trails"> 
                                <Typography className={classes.linkHeader} variant="h4">
                                    View and Edit Trails
                                </Typography>
                            </Link>
                            <Typography variant="h6">
                                Total Trails: {props.trails ? props.trails.length : 0}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid className={classes.linkHeaderWrapper} container justify="center">
                    <Grid item xs={7}>
                        <Box className={classes.buttonBox}>
                            <Link to="/admin/users"> 
                                <Typography className={classes.linkHeader} variant="h4">
                                    View and Edit Users
                                </Typography>
                            </Link>
                            <Typography variant="h6">
                                Total Users: {props.users ? props.users.length : 0}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    );
}

const mapStateToProps = state => {
	return {
		token : state.auth_reducer.token,
		user: state.user_reducer.user,
        users: state.user_reducer.users,
		lake : state.user_reducer.lake,
        trails : state.trail_reducer.trails,
        events : state.event_reducer.events
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId)),
		lakeLoad : (lakeId) => dispatch(actions.getLake(lakeId)),
        eventsLoad: () => dispatch(actions.getEvents()),
        trailsLoad: () => dispatch(actions.getTrails()),
        usersLoad: () => dispatch(actions.getUsers())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);