import * as React from "react";
import { IconButton, Drawer, List, ListItem, ListItemText, Grid } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as actions from '../../store/actions/index';
import  {connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    list: {
      width: 250,
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `black`,
      cursor: 'pointer'
    },
    mobileNav: {
        alignItems: 'flex-start'
    },
    navbarDisplayFlex: {
		/*display: `flex`,*/
		justifyContent: `space-between`
	}
});

const SideDrawer = ({ navLinks, props }) => {
    const classes = useStyles();
    const [state, setState] = useState({ right: false })
    const toggleDrawer = (anchor, open) => event => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return
        }
        setState({ [anchor]: open })
    }
    const sideDrawerList = anchor => (
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
            <List component="nav">
            {navLinks.map(({ title, path }) => (
                <Grid container item xs={12}>
                    <NavLink key={"nav_"+title.replace(' ', '')} to={path} className={classes.linkText} activeClassName="selected">
                        <Typography variant="h6">{title}</Typography>
                    </NavLink>
                </Grid>
            ))}
            {props.userId && props.user ? <a className={classes.linkText} onClick = { ()=>props.onLogOut()}><Typography variant="h6">Logout</Typography></a> :
            <NavLink key={"login"} to="/auth" className={classes.linkText}>
                <Typography variant="h6">Login</Typography>
            </NavLink>}

            </List>
        </div>
    );
    return (
        <React.Fragment>
            <IconButton style={{position:'absolute', top:'0'}} edge="start" aria-label="menu" onClick={toggleDrawer("right", true)}>
                <Drawer
                    drawerBackgroundColor="transparent"
                    className={classes.mobileNav}
                    anchor="right"
                    open={state.right}
                    onOpen={toggleDrawer("right", true)}
                    onClose={toggleDrawer("right", false)}>
                {sideDrawerList("right")}
                </Drawer>
                <Menu fontSize="large" style={{ color: `black` }} />
            </IconButton>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
	return {
		userId : state.auth_reducer.userId,
		user: state.user_reducer.user
 	}
}

const mapDispatchToProps = dispatch => {
	return {
		userLoad : (userId) => dispatch(actions.getUser(userId)),
		onLogOut : () => dispatch(actions.authLogout())
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(SideDrawer);